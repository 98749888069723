import React from 'react';
import './MammographyStepsIndicator.css';

const steps = ['Mammography', 'Extract metadata', 'Upload', 'BI-RADS Score (AI)'];

function MammographyStepsIndicator({ stage }) {
  return (
    <div className="steps">
      {steps.map((step, i) => (
        <div className="step-container" key={i}>
          <div className={`circle ${stage >= i && stage > 0 ? 'active' : 'notActive'} ${stage === i && 'loading'}`}>
            {i + 1}
          </div>
          <div className={`title ${stage >= i ? 'active' : 'notActive'}`}>
            {step}
          </div>
        </div>
      ))}
    </div>
  );
}

export default MammographyStepsIndicator;



