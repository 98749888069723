const activityLog = []; // Store the activity log

export const logActivity = {
  start: (title) => {
    const transactionID = generateTransactionID();
    const transaction = {
      id: transactionID,
      title,
      startTime: new Date().toISOString(),
      logEntries: [],
    };
    if (!findTransactionByID(transactionID)) {
      activityLog.push(transaction); // Added the condition to check for duplicate transactions
    }
    return transactionID;
  },
  addEntry: (transactionID, style, message, data) => {
    const timestamp = new Date().toISOString();
    const logEntry = {
      timestamp,
      style,
      message,
      data,
    };
    const transaction = findTransactionByID(transactionID);
    if (transaction) {
      transaction.logEntries.push(logEntry);
    }
  },
  end: (transactionID) => {
    const transaction = findTransactionByID(transactionID);
    if (transaction) {
      transaction.endTime = new Date().toISOString();
    }
  },
  getActivityLog: () => activityLog,
};

// Utility function to generate a unique transaction ID
const generateTransactionID = () => {
  return Math.random().toString(36).substr(2, 9);
};

// Utility function to find a transaction by its ID
const findTransactionByID = (transactionID) => {
  return activityLog.find((transaction) => transaction.id === transactionID);
};

