import React, { useState, useRef } from "react";
import mic from "./img/mic.png"; // Import the mic.png icon

const SpeechToText = ({ onSpeechResult }) => {
  const [isRecording, setIsRecording] = useState(false);
  const recognitionRef = useRef(null);

  const handleSpeechRecognitionResult = (event) => {
    let result = "";
    for (let i = event.resultIndex; i < event.results.length; i++) {
      result += event.results[i][0].transcript;
    }
    onSpeechResult(result);
  };

  const toggleSpeechRecognition = () => {
    if (isRecording) {
      setIsRecording(false);
      recognitionRef.current.stop();
    } else {
      recognitionRef.current = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
      const recognition = recognitionRef.current;
      recognition.continuous = true; // Set to true for continuous recording
      recognition.interimResults = true;
      recognition.lang = "en-US";

      recognition.onstart = () => {
        setIsRecording(true);
      };

      recognition.onresult = (event) => {
        handleSpeechRecognitionResult(event);
      };

      recognition.onerror = () => {
        setIsRecording(false);
      };

      recognition.start();
    }
  };

  return (
    <button
      onClick={toggleSpeechRecognition}
      className={`mb-2 w-6 h-6 bg-red-400 rounded-full focus:outline-none inline-flex items-center justify-center ${
        isRecording ? "animate-pulse" : ""
      }`}
    >
      <img
        src={mic} // Replace with the actual path to your mic.png icon
        alt="Microphone"
        className="w-3 h-4 pointer-events-none"
      />
    </button>
  );
};

export default SpeechToText;
