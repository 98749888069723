import React, { useState, useEffect, useContext, createContext } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { nanoid } from 'nanoid';
import * as Realm from 'realm-web';
import { countries } from './locales.js';
import { AppContext } from '../../context/AppContext.js'

const REALM_APP_ID = process.env.REACT_APP_REALM_APP_ID;
const username = process.env.REACT_APP_HC_ID;
const password = process.env.REACT_APP_HC_PASS;

const realmApp = new Realm.App({ id: REALM_APP_ID });

const credentials = Realm.Credentials.emailPassword(username, password);
const user = await realmApp.logIn(credentials);

const UserNewSession = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [purpose, setPurpose] = useState('');
  const [country, setCountry] = useState('');
  const [showModal, setShowModal] = useState(true);
  const [isFormValid, setIsFormValid] = useState(false);
  const { translations } = useContext(AppContext);

  useEffect(() => {
    const storedName = localStorage.getItem('name');
    const storedEmail = localStorage.getItem('email');
    const storedCountry = localStorage.getItem('country');

    if (storedName) {
      setName(storedName);
    }

    if (storedEmail) {
      setEmail(storedEmail);
    }

    if (storedCountry) {
      setCountry(storedCountry);
    }

    let connectionKeyAppointments = 'MongoDBConnection.appointments';
    let connectionKeyClinicalReports = 'MongoDBConnection.clinicalreports';

    localStorage.setItem(connectionKeyAppointments, 'false');
    localStorage.setItem(connectionKeyClinicalReports, 'false');

  }, []);


  useEffect(() => {
    setIsFormValid(name !== '' && email !== '' && purpose !== '' && country !== '');
  }, [name, email, purpose, country]);

  const fetchIpAddress = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error('Error fetching IP address:', error);
    }
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePurposeChange = (event) => {
    setPurpose(event.target.value);
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!isFormValid) {
      return;
    }

    const sessionId = nanoid();
    localStorage.setItem('name', name);
    localStorage.setItem('email', email);
    localStorage.setItem('country', country);

    const dateTime = new Date();
    const userAgent = navigator.userAgent;
    const ipAddress = await fetchIpAddress(); // Await the IP address promise

    const mongodb = realmApp.currentUser.mongoClient('mongodb-atlas');
    const collection = mongodb.db('demo_hc_virtual_hospital_core').collection('user-sessions');

    await collection.insertOne({
      sessionId,
      name,
      email,
      purpose,
      dateTime,
      userAgent,
      ipAddress,
      country,
    });

    setShowModal(false);
  };

  const handleCloseModal = () => {
    // Do nothing when closing the modal
  };

  const isEmailValid = () => {
    // Use a regular expression to validate the email format
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  return (
    <Modal show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton={false}>
        <Modal.Title>{translations.newSessionForm.welcomeToTheDemo}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="my-2" controlId="nameInput">
            <Form.Label>* {translations.newSessionForm.name}:</Form.Label>
            <Form.Control
              type="text"
              className="w-64"
              value={name}
              onChange={handleNameChange}
              required
            />
          </Form.Group>
          <Form.Group className="my-2" controlId="emailInput">
            <Form.Label>* {translations.newSessionForm.email}:</Form.Label>
            <Form.Control
              type="email"
              className="w-64"
              value={email}
              onChange={handleEmailChange}
              required
              isInvalid={!isEmailValid()} // Add isInvalid prop for email validation
            />
            {/* Add feedback for invalid email format */}
            <Form.Control.Feedback type="invalid">
              {translations.newSessionForm.invalidEmail}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="my-2" controlId="countryInput">
            <Form.Label>* {translations.newSessionForm.country}:</Form.Label>
            <Form.Control
              as="select"
              className="w-64"
              value={country}
              onChange={handleCountryChange}
              required
            >
              <option value="">{translations.newSessionForm.selectCountry}</option>
              {countries.map((countryObj) => (
                <option key={countryObj.code} value={countryObj.name}>
                  {countryObj.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group className="my-2" controlId="purposeInput">
            <Form.Label>* {translations.newSessionForm.purpose}:</Form.Label>
            <Form.Control
              as="select"
              className="w-64"
              value={purpose}
              onChange={handlePurposeChange}
              required
            >
              <option value="">{translations.newSessionForm.selectPurpose}</option>
              <option value="Customer session">{translations.newSessionForm.customerSession}</option>
              <option value="Sales enablement for healthcare">
                {translations.newSessionForm.salesEnablement}
              </option>
              <option value="Test - Development">{translations.newSessionForm.testDevelopment}</option>
              <option value="Other">{translations.newSessionForm.other}</option>
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex justify-end">
          <button
            className={`px-4 py-1 text-sm font-semibold rounded-full hover:text-white hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-green-800 focus:ring-offset-2 
              ${isFormValid ? 'text-green-700 border border-gray-200 hover:border-transparent' : 'text-gray-400 bg-gray-200 cursor-not-allowed'}`}
            variant="primary"
            onClick={handleSubmit}
            disabled={!isFormValid} // This will disable the button when isFormValid is false
          >
            {translations.newSessionForm.startDemoButton}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default UserNewSession;





