import React, { Fragment, useContext, useState, useEffect } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { NavLink,useLocation } from 'react-router-dom'
import { AppContext } from '../../context/AppContext';
import { getLocationId } from '../../services/APIService';
import logo from './img/leafy_logo.png'
import admin from './img/admin.png'
import radio from './img/radio.png'
import preferencesIcon from './img/preferencesIcon.png'



function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function NavBar() {
  const { currentAppUser, setCurrentAppUser } = useContext(AppContext);
  const { currentCenter, setCurrentCenter } = useContext(AppContext);
  const location = useLocation();
  const { preferences } = useContext(AppContext);


  const getCenterName = async () => {
    try {
      const center = await getLocationId(preferences, currentAppUser);
      setCurrentCenter(center);
      return center
    } catch (error) {
      console.error('Error fetching center name:', error);
      setCurrentCenter('Unknown Medical Center');
    }
  };

  useEffect(() => {
    getCenterName();
  }, [currentAppUser, currentCenter, location]);

  
  const navigation = currentAppUser === 'hc-provider'
    ? [
        { name: 'Project Overview', href: '/' },
        { name: 'Screening List', href: '/screening' },
      ]
    : [
        { name: 'Project Overview', href: '/' },
        { name: 'Screening List', href: '/screening' },
        { name: 'Overall Status', href: '/overall' },
        { name: 'Advanced Search', href: '/advancedSearch' },
      ];

  const toggleUser = () => {
    const newUser = currentAppUser === 'hc-provider' ? 'hc-agency' : 'hc-provider';
    setCurrentAppUser(newUser);
  };

  return (
    <>
      <Disclosure as="nav" className="sticky drop-shadow-sm top-0 z-50 bg-white">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
              <div className="relative flex h-16 items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button*/}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex pt-2 flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                  <NavLink to="/" className="flex flex-shrink-0 items-center">
                    <img
                      className="block h-12 w-auto lg:hidden"
                      src={logo}
                      alt="Leafy Hospital"
                    />
                    <img
                      className="hidden h-12 w-auto lg:block"
                      src={logo}
                      alt="Leafy Hospital"
                    />
                  </NavLink>
                  <div className="hidden sm:ml-6 sm:block">
                    <div className="flex space-x-4">
                      {navigation.map((item) => (
                        <NavLink
                          key={item.name}
                          to={item.href}
                          className={({ isActive }) => {
                            return 'rounded-md px-3 py-2 text-sm font-medium no-underline ' +
                              (!isActive ? 'text-gray-300 hover:bg-gray-700 hover:text-white no-underline' : 'bg-gray-900 text-white no-underline')
                          }}
                        >
                          {item.name}
                        </NavLink>
                      ))}
                    </div>
                  </div>
                </div>


                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">


                  {/* Preferences */}
                  <NavLink to="/preferences" className="rounded-full shadow-md bg-white p-2 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                    <span className="sr-only">View notifications</span>
                    <img className="h-6 w-6 rounded-full" src={preferencesIcon} alt="" />
                  </NavLink>

                  {currentAppUser === 'hc-agency' && (

                    <Menu as="div" className=" relative ml-3">
                      <div>

                        <Menu.Button className="flex items-center justify-between px-3 rounded-xl bg-white shadow-md text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">

                          <div className="flex items-center">
                            <img
                              className="h-8 w-8 rounded-full"
                              src={admin}
                              alt=""
                            />
                            <div className="text-left ml-3">
                              <p className='mb-0 font-bold'>Eddie Grant</p>
                              <p className='mb-0 pb-1'>Leafy Healthcare Agency</p>
                            </div>
                          </div>
                          <span className="sr-only">Open user menu</span>
                        </Menu.Button>

                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-55 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                onClick={toggleUser}
                              >
                                <span className="sr-only">Open user menu</span>
                                <p className='text-gray-400'>Switch user to:</p>
                                <img
                                  className="h-8 w-8 rounded-full"
                                  src={radio}
                                  alt=""
                                />
                                <p className='mb-0 pb-1 pt-2 font-bold'>Bea Campos</p>
                                <p className='mb-0 pb-1'>{currentCenter}</p>
                              </a>
                            )}
                          </Menu.Item>

                        </Menu.Items>
                      </Transition>
                    </Menu>
                  )}
                  {currentAppUser === 'hc-provider' && (<Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button className="flex items-center justify-between px-3 rounded-xl bg-white shadow-md text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <div className="flex items-center">
                          <img
                            className="h-8 w-8 rounded-full"
                            src={radio}
                            alt=""
                          />
                          <div className="text-left ml-3">
                            <p className='mb-0 font-bold'>Bea Campos</p>
                            <p className='mb-0 pb-1'>{currentCenter}</p>
                          </div>
                        </div>
                        <span className="sr-only">Open user menu</span>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-55 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                              onClick={toggleUser}
                            >
                              <span className="sr-only">Open user menu</span>
                              <p className='text-gray-400'>Switch user to:</p>
                              <img
                                className="h-8 w-8 rounded-full"
                                src={admin}
                                alt=""
                              />
                              <p className='mb-0 pb-1 pt-2 font-bold'>Eddie Grant</p>
                              <p className='mb-0 pb-1'>Leafy Healthcare Agency</p>
                            </a>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                  )}
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'block rounded-md px-3 py-2 text-base font-medium'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <div className="px-10 py-10"></div>
    </>
  )
}
