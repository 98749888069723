import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import './Alert.css';

const Alert = ({ title, message, onClose }) => {
  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.keyCode === 27) {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [onClose]);

  const handleModalClose = () => {
    onClose();
  };

  return (
    <div className="alert-overlay">
      <div className="alert-container">
        <h3 className="alert-title">{title}</h3>
        <p className="alert-message">{message}</p>
        <Button variant="primary" onClick={handleModalClose}>
          Close
        </Button>
      </div>
    </div>
  );
};

export default Alert;
