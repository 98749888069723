import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import './Alert.css';

const AlertReload = ({ title, message, onReload }) => {
  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.keyCode === 27) {
        onReload();
      }
    };

    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [onReload]);

  const handleReload = () => {
    onReload();
  };

  return (
    <div className="alert-overlay">
      <div className="alert-container">
        <h3 className="alert-title">{title}</h3>
        <p className="alert-message">{message}</p>
        <Button variant="primary" onClick={handleReload}>
          Reload
        </Button>
      </div>
    </div>
  );
};

export default AlertReload;
