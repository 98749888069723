import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';
import github from './img/github.png';
import slide from './img/slide.png';
import data_journey from './img/data_journey.png';
import info from "./img/info.png";

export default function Overview() {

    return (

        <Container>
            <div>

                <h1 className='text-center'>Welcome to our Virtual Hospital Demo!</h1>

                <div className="flex items-center justify-center">
                    <img
                        className="w-75"
                        src={data_journey}
                        alt=""

                    ></img>

                </div>

            </div>

            <div className="flex justify-center items-center">
                <p  className="mt-3 mx-2"> Look out for </p>
                <img
                    className="h-5 w-5"
                    src={info}
                    alt=""
                />

                <p className="mt-3 mx-2">throughout the demo to learn more about the underlying technologies!</p>

            </div>


            <div className='my-10 flex justify-center'>


                <a
                    href="https://github.com/mongodb-industry-solutions/Leafy-Hospital-Demo"
                    className="ml-3 flex text-black items-center justify-between px-3 rounded-xl bg-white shadow-md text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                >
                    <div className="flex items-center">
                        <img
                            className="h-8 w-8"
                            src={github}
                            alt=""
                        />
                        <div className="text-left ml-3">
                            <p className="mb-0 font-bold">Github Repo</p>
                            <p className="mb-0 pb-1">Want to contribute to out project?</p>
                        </div>
                    </div>
                    <span className="sr-only">Open user menu</span>
                </a>

                <a
                    href="https://docs.google.com/presentation/d/1XmyazdopEf9Dz61BgIKwnTU8YRmExagSXtVD--MUxU8/edit#slide=id.g21380c72780_0_2452"
                    className="ml-3 flex text-black items-center justify-between px-3 rounded-xl bg-white shadow-md text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                >
                    <div className="flex items-center">
                        <img
                            className="h-8 w-6"
                            src={slide}
                            alt=""
                        />
                        <div className="text-left ml-3">
                            <p className="mb-0 font-bold">Project Presentation</p>
                            <p className="mb-0 pb-1">Check out our full project storyline</p>
                        </div>
                    </div>
                    <span className="sr-only">Open user menu</span>
                </a>
            </div>

            <p className='text-center pb-10'>Any questions? Get in touch at <a className='text-green-700 underline' href="mailto:industry.solutions@mongodb.com">industry.solutions@mongodb.com</a></p>


        </Container>

    )

}