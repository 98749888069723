import React, { createContext, useState, useEffect } from 'react';
import * as Realm from 'realm-web';

const AppContext = createContext();

const REALM_APP_ID = process.env.REACT_APP_REALM_APP_ID;
const username = process.env.REACT_APP_HC_ID;
const password = process.env.REACT_APP_HC_PASS;

const realmApp = new Realm.App({ id: REALM_APP_ID });

const AppProvider = ({ children }) => {
  const [preferences, setPreferences] = useState({});
  const [translations, setTranslations] = useState({});
  const [activityLog, setActivityLog] = useState([]);
  const [isInitialized, setIsInitialized] = useState(false);
  const [currentAppUser, setCurrentAppUser] = useState('hc-provider');
  const [currentCenter, setCurrentCenter] = useState('');

  useEffect(() => {
    if (!isInitialized) {
      const fetchPreferences = async () => {
        try {
          const credentials = Realm.Credentials.emailPassword(username, password);
          const user = await realmApp.logIn(credentials);
          const mongodb = realmApp.currentUser.mongoClient('mongodb-atlas'); 
          const collection = mongodb.db('demo_hc_virtual_hospital_core').collection('user-preferences');
          const email = localStorage.getItem('email');

          // Check if user preferences exist
          const userPreferencesDoc = await collection.findOne({ _id: email });

          if (userPreferencesDoc) {
            // User preferences exist, set them as the current preferences
            setPreferences(userPreferencesDoc);

            if (userPreferencesDoc.locale && userPreferencesDoc.locale.lng) {
              const languageCollection = mongodb.db('demo_hc_virtual_hospital_core').collection('lng');
              const translationsDoc = await languageCollection.findOne({ lng: userPreferencesDoc.locale.lng });
              setTranslations(translationsDoc.translations);
            }
          } else {
            // User preferences don't exist, create a new document based on admin preferences
            const adminPreferencesDoc = await collection.findOne({ _id: 'admin' });

            if (adminPreferencesDoc) {
              // Copy admin preferences for the new user
              const newUserPreferencesDoc = { ...adminPreferencesDoc };
              newUserPreferencesDoc._id = email;
              await collection.insertOne(newUserPreferencesDoc);
              setPreferences(newUserPreferencesDoc);

              if (newUserPreferencesDoc.locale && newUserPreferencesDoc.locale.lng) {
                const languageCollection = mongodb.db('demo_hc_virtual_hospital_core').collection('lng');
                const translationsDoc = await languageCollection.findOne({ lng: newUserPreferencesDoc.locale.lng });
                setTranslations(translationsDoc.translations);
              }
            }
          }
        } catch (error) {
          console.error('Error fetching preferences:', error);
        } finally {
          setIsInitialized(true);
        }
      };

      fetchPreferences()
        .then(() => {
        })
        .catch((error) => {
          console.error('Error logging preferences and translations:', error);
        });
    } else {
      // Preferences have been updated, fetch translations again if preferences.locale exists
      if (preferences.locale && preferences.locale.lng) {
        const fetchTranslations = async () => {
          try {
            const mongodb = realmApp.currentUser.mongoClient('mongodb-atlas');
            const languageCollection = mongodb.db('demo_hc_virtual_hospital_core').collection('lng');
            const translationsDoc = await languageCollection.findOne({ lng: preferences.locale.lng });
            setTranslations(translationsDoc.translations);
          } catch (error) {
            console.error('Error fetching translations:', error);
          }
        };

        fetchTranslations()
          .then(() => {
          })
          .catch((error) => {
            console.error('Error fetching translations:', error);
          });
      }
    }
  }, [isInitialized, preferences.locale]);

  // Wait for translations to load before rendering the children
  if (Object.keys(translations).length === 0) {
    return <div>Loading configuration...</div>;
  }

  const contextValue = {
    preferences,
    setPreferences,
    translations,
    activityLog,
    setActivityLog,
    currentAppUser,
    setCurrentAppUser,
    currentCenter,
    setCurrentCenter,
  };

  return (
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };

