import React, { useState, useContext, useEffect } from "react";
import * as Realm from "realm-web";
import MammographyStepsIndicator from "../MammographyStepsIndicator/MammographyStepsIndicator";
import Alert from "../Alert/Alert";
import ReportForm from "../ReportForm/ReportForm";
import { Button, Modal } from "react-bootstrap";
import { AppContext } from '../../context/AppContext';
import L_CCImage from "./img/L_CC.png";
import L_MLOImage from "./img/L_MLO.png";
import R_CCImage from "./img/R_CC.png";
import R_MLOImage from "./img/R_MLO.png";
import loadingSpinner from "./img/spinner.gif";
import info from "./img/info.png";
import info_mamm from "./img/info_mamm.png";
import "./Screening.css";
import close from "./img/close.png";
import { APIProcessMammography, APIGenerateAndGetVector, APISaveClinicalReport, APIUpdateAppointmentStatus } from '../../services/APIService';

function Screening({ handleClose, appointment }) {
    const [lastDocument, setLastDocument] = useState({});
    const [imageDetails, setImageDetails] = useState({
        "L-CC": false,
        "L-MLO": false,
        "R-CC": false,
        "R-MLO": false,
    });
    const [loadingStatus, setLoadingStatus] = useState({});
    const [showReportForm, setShowReportForm] = useState(false);
    const [events, setEvents] = useState([]);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [stage, setStage] = useState(-1);
    const [requestedImage, setRequestedImage] = useState({
        requestedLaterality: null,
        requestedViewPosition: null,
    });
    const [clinicalNotes, setClinicalNotes] = useState("");
    const [conclusions, setConclusions] = useState("");
    const [conclusionsSNOMED, setConclusionsSNOMED] = useState("");
    const { preferences, currentAppUser } = useContext(AppContext);
    const [isLoadingGeneratedText, setIsLoadingGeneratedText] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("");

    const handleExpandReportForm = () => {
        setShowReportForm(true);
    };
    const toggleReportForm = () => {
        setShowReportForm(!showReportForm);
    };

    const handleCloseErrorAlert = () => {
        setShowErrorAlert(false);
    };

    const handleErrorAlertClose = () => {
        setShowErrorAlert(false);
    };

    //Info pop-up
    const [showInfoPopup, setShowInfoPopup] = useState(false);

    const handleInfoButtonClick = () => {
        setShowInfoPopup(true);
    };

    const handlePopupClose = () => {
        setShowInfoPopup(false);
    };

    const REALM_APP_ID = process.env.REACT_APP_REALM_APP_ID;

    const realmApp = new Realm.App({ id: REALM_APP_ID });

    const calculateAge = (birthdate) => {
        if (!birthdate) {
            return "";
        }

        const birthDateObj = new Date(birthdate);
        const currentDate = new Date();
        let age = currentDate.getFullYear() - birthDateObj.getFullYear();

        // Adjust age based on the month and day
        if (
            currentDate.getMonth() < birthDateObj.getMonth() ||
            (currentDate.getMonth() === birthDateObj.getMonth() &&
                currentDate.getDate() < birthDateObj.getDate())
        ) {
            age--;
        }

        // Format age in DICOM format
        const ageString = age.toString().padStart(3, "0") + "Y";

        return ageString;
    };


    const patientId = appointment.subject.reference || "";
    const givenName =
    appointment.subject.display?.[0]?.given?.[0] || "";
    const familyName = appointment.subject.display?.[0]?.family || "";
    const birthdate = new Date(
        appointment.subject.birthDate
    ).toISOString();
    const age = calculateAge(birthdate);
    const radiologist =
        appointment.participant[1]?.actor?.display?.[0]?.family || "";
    const institution = appointment.location?.display || "";


    const ImageScore = ({ value }) => {
        let scoreColorClass = "";

        if (value < 3) {
            scoreColorClass = "green";
        } else if (value >= 3 && value <= 4) {
            scoreColorClass = "orange";
        } else {
            scoreColorClass = "red";
        }

        return (
            <p id="imageBiRadScore" className={`image-score ${scoreColorClass}`}>
                {value}
            </p>
        );
    };

    const ScreeningImageDetails = ({ laterality, viewPosition }) => {

        const matchingImage = lastDocument.series?.[laterality]?.[viewPosition] || null;
        const imageMetadata = matchingImage?.imageMetadata;

        const { StudyDate, StudyTime } = imageMetadata || {};

        if (!StudyDate || !StudyTime || StudyDate.length !== 8 || StudyTime.length !== 6) {
            return null; // Return null if the date and/or time are not valid
        }
        // Extract year, month, and day from the StudyDate string
        const year = parseInt(StudyDate.substr(0, 4));
        const month = parseInt(StudyDate.substr(4, 2)) - 1; // Months are 0-indexed in JavaScript
        const day = parseInt(StudyDate.substr(6, 2));

        // Extract hours, minutes, and seconds from the StudyTime string
        const hours = parseInt(StudyTime.substr(0, 2));
        const minutes = parseInt(StudyTime.substr(2, 2));
        const seconds = parseInt(StudyTime.substr(4, 2));

        // Construct a new Date object with the extracted date and time components
        const dateTime = new Date(year, month, day, hours, minutes, seconds);

        const biRadScore = imageMetadata?.biRads || "";

        return (
            <div className="image-details">
                <div className="image-row">
                    <label className="image-label">Laterality: </label>
                    <p className="image-text">{laterality}</p>
                </div>
                <div className="image-row">
                    <label className="image-label">View Position: </label>
                    <p className="image-text">{viewPosition}</p>
                </div>
                {dateTime && (
                    <div className="image-row">
                        <label className="image-label">Date: </label>
                        <p className="image-text">{dateTime.toLocaleDateString()}</p>
                    </div>
                )}
                {dateTime && (
                    <div className="image-row">
                        <label className="image-label">Time: </label>
                        <p className="image-text">{dateTime.toLocaleTimeString()}</p>
                    </div>
                )}
                {biRadScore && (
                    <div className="image-row">
                        <label className="image-label">BI-Rad Score: </label>
                        <ImageScore value={biRadScore} />
                    </div>
                )}
            </div>
        );
    };

    const ScreeningImageContainer = ({ laterality, viewPosition }) => {
        const matchingImage = lastDocument.series?.[laterality]?.[viewPosition] || null;
        const isLoading = loadingStatus[`${laterality}_${viewPosition}`] || false;
        const Base64Image = matchingImage?.imageMetadata?.Base64Image;
        const awsS3URL = matchingImage?.imageMetadata?.AWS_S3_URL || "";
        let isImageUploaded = false;
        if (awsS3URL && awsS3URL !== "Pending to upload...") {
            isImageUploaded = true;
        }

        const buttonText = isImageUploaded ? "Download" : "Take image";

        let placeholder;
        if (laterality === "L" && viewPosition === "CC") {
            placeholder = L_CCImage;
        } else if (laterality === "L" && viewPosition === "MLO") {
            placeholder = L_MLOImage;
        } else if (laterality === "R" && viewPosition === "CC") {
            placeholder = R_CCImage;
        } else if (laterality === "R" && viewPosition === "MLO") {
            placeholder = R_MLOImage;
        }


        const handleImageClick = async () => {
            setLoadingStatus({ ...loadingStatus, [`${laterality}_${viewPosition}`]: true });

            if (isImageUploaded) {
                // If the image is uploaded, trigger the download functionality
                const link = document.createElement("a");
                link.href = awsS3URL;
                link.target = "_blank"; // Open the link in a new tab
                link.download = `${laterality}-${viewPosition}.dcm`; // Set the downloaded file's name

                // Programmatically click on the link to trigger the download
                link.click();
            } else {
                // If the image is not uploaded, proceed with the normal execution
                await executeScriptMammography(laterality, viewPosition);
            }
        };


        return (
            <div
                className="image-container-screening"
                data-content={buttonText}
            >
                <button className="image-button" onClick={handleImageClick}>
                    {isLoading && !isImageUploaded && <img className="loading-spinner" src={loadingSpinner} alt="Loading" />}
                    {Base64Image ? (
                        <img
                            id="image"
                            src={`data:image/jpeg;base64, ${Base64Image}`}
                            className="uploaded-image"
                        />
                    ) : (
                        <img
                            id="image"
                            src={placeholder}
                            className="placeholder-image"
                        />
                    )}
                </button>
                <ScreeningImageDetails laterality={laterality} viewPosition={viewPosition} />
            </div>
        );
    };


    const processNewDocument = (doc) => {

        if (!doc) {
            console.log("Document is null or undefined.");
            return;
        }

        const series = doc.series;
        if (stage === 0 && (!series || Object.keys(series).length === 0)) {
            setRequestedImage({
                requestedLaterality: null,
                requestedViewPosition: null,
            });
            setStage(1);
        }
    };

    const processUpdateDocument = (doc) => {
        const updatedDocumentLaterality = doc.lastUpdatedLaterality;
        const updatedDocumentviewPosition = doc.lastUpdatedViewPosition;
        const imageMetadata = doc.series?.[updatedDocumentLaterality]?.[updatedDocumentviewPosition]?.imageMetadata;
        const awsS3URL = doc.series?.[updatedDocumentLaterality]?.[updatedDocumentviewPosition]?.imageMetadata.AWS_S3_URL;
        const biRads = doc.series?.[updatedDocumentLaterality]?.[updatedDocumentviewPosition]?.imageMetadata.biRads;

        if (awsS3URL === "Pending to upload..." && !biRads) {
            setStage(2);
        }

        if (awsS3URL != "Pending to upload..." && !biRads) {
            setStage(3);
        }

        if (biRads) {
            setStage(4);
            setRequestedImage({
                requestedLaterality: null,
                requestedViewPosition: null,
            });
        }
    };


    const processMammography = async (params) => {
        try {
            await APIProcessMammography(params, preferences, currentAppUser);
        } catch (error) {
            setShowErrorAlert(true);
            console.error("Error executing the Dicom extract and upload:", error);
        }
    };

    const executeScriptMammography = (laterality, viewPosition) => {

        setRequestedImage({
            requestedLaterality: laterality,
            requestedViewPosition: viewPosition
        });

        setStage(0);

        const params = new URLSearchParams({
            patientId: patientId,
            name: givenName + " " + familyName,
            age: age,
            birthdate: birthdate,
            radiologist: radiologist,
            institution: institution,
            appointmentID: appointment._id,
            laterality: laterality,
            viewPosition: viewPosition,
            seriesID: lastDocument.seriesID || "-", // Use the stored seriesID or fallback to "-"
        });

        processMammography(params);
    };


    useEffect(() => {
        const login = async () => {
            const mongodb = realmApp.currentUser.mongoClient("mongodb-atlas");
            const collection = mongodb.db("demo_hc_virtual_hospital").collection("dicom_images");

            // Every time a change happens in the stream, add it to the list of events
            for await (const change of collection.watch()) {
                setLastDocument(change.fullDocument);
                if (change.operationType === "insert") {
                    setEvents((events) => [...events, { change, index: -1 }]);
                    processNewDocument(change.fullDocument);
                } else if (change.operationType === "update") {
                    processUpdateDocument(change.fullDocument);
                }
            }
        };

        login();
    }, []);


    const saveReport = async () => {
        try {
            setIsLoadingGeneratedText(true);

           
           // const query = `Clinical Notes: ${clinicalNotes} Conclusions: ${conclusions}`; 
           //Need to return to previous code, but current algorithm is too limited for text input

            const query = conclusions;

            const vectorizedPrompt = await APIGenerateAndGetVector(query, preferences, currentAppUser);

            await APISaveClinicalReport(lastDocument, clinicalNotes, conclusions, conclusionsSNOMED, vectorizedPrompt, preferences, currentAppUser);
            await APIUpdateAppointmentStatus(appointment._id, "fulfilled", preferences, currentAppUser);

            alert("Report saved successfully!");

            setIsLoadingGeneratedText(false);
            setShowReportForm(false);
        } catch (error) {
            console.error("Error saving report:", error);

            const errorMessage = "An error occurred while saving the report. Please try again later.";

            setShowErrorAlert(true);
            setLoadingMessage(errorMessage);

            setIsLoadingGeneratedText(false);
        }
    };

    return (
        <>
            <Modal show={true} onHide={handleClose} className="modal" centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Mammography: {givenName} {familyName}
                    </Modal.Title>
                    <button
                        onClick={handleClose}
                        className="absolute top-0 right-0 mt-2 mr-4 text-gray-500 rounded-xl bg-white shadow-md text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                    >
                        <div className="flex items-center px-2">
                            <img className="h-4 w-4 rounded-full" src={close} alt="" />
                            <div className="text-left ml-1">
                                <p className="mb-0 text-red-500 ">Close</p>
                            </div>
                        </div>
                    </button>
                </Modal.Header>
                <Modal.Body className="modal-content">

                    <div className="flex justify-center items-center">
                        <button
                            type="button"
                            onClick={handleInfoButtonClick}
                            className="text-gray-600 rounded-full border border-gray-200 hover:bg-purple-200"
                        >
                            <img src={info} alt="Info" className="h-5 w-5" />
                        </button>
                    </div>

                    <MammographyStepsIndicator stage={stage} />
                    <div className="images-screening">
                        <ScreeningImageContainer
                            laterality="L" viewPosition="CC"
                        />
                        <ScreeningImageContainer
                            laterality="L" viewPosition="MLO"
                        />
                        <ScreeningImageContainer
                            laterality="R" viewPosition="CC"
                        />
                        <ScreeningImageContainer
                            laterality="R" viewPosition="MLO"
                        />
                    </div>
                    {showReportForm && <ReportForm
                        lastDocument={lastDocument}
                        age={age}
                        clinicalNotes={clinicalNotes}
                        setClinicalNotes={setClinicalNotes}
                        conclusions={conclusions}
                        setConclusions={setConclusions}
                        conclusionsSNOMED={conclusionsSNOMED}
                        setConclusionsSNOMED={setConclusionsSNOMED}
                    />}
                    {showErrorAlert && (
                        <Alert
                            title="Error"
                            message="The endpoint to execute the Dicom extract and upload is not running. Please review the documentation."
                            onClose={handleCloseErrorAlert}
                        />
                    )}

                    <button
                        variant="primary"
                        onClick={toggleReportForm}
                        className="toggleButton px-3 py-1 text-sm text-gray-600 font-semibold rounded-full border border-gray-200 hover:text-white hover:bg-gray-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-offset-2"
                    >
                        {showReportForm ? "Hide Report Form" : "Show Report Form"}
                    </button>
                </Modal.Body>

                <Modal.Footer>
                    <button
                        variant="primary"
                        className="px-4 py-1 text-sm text-green-700 font-semibold rounded-full border border-gray-200 hover:text-white hover:bg-green-800 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-green-800 focus:ring-offset-2"
                        onClick={saveReport}
                    >
                        Save Report
                    </button>
                </Modal.Footer>
                {showInfoPopup && (
                    <div
                        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
                        onClick={handlePopupClose}
                    >
                        <div className="bg-white mx-6 p-2 rounded-lg shadow-lg w-80%">
                            <h2 className="text-center">Mammography Process Architecture</h2>
                            <img src={info_mamm} alt="Info" className="h-70% w-70% object-contain mx-auto" />
                        </div>
                    </div>
                )}
            </Modal>
        </>
    );
};

export default Screening;
