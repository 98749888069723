import React, { useState, useContext } from 'react';
import './ImageContainer.css'
import { AppContext } from '../../context/AppContext';
import L_CCImage from "./img/L_CC.png";
import L_MLOImage from "./img/L_MLO.png";
import R_CCImage from "./img/R_CC.png";
import R_MLOImage from "./img/R_MLO.png";
import DicomViewer from '../DicomViewer/DicomViewer';

const ImageScore = ({ value }) => {

    let scoreColorClass = '';

    if (value < 3) {
        scoreColorClass = 'green';
    } else if (value >= 3 && value <= 4) {
        scoreColorClass = 'orange';
    } else {
        scoreColorClass = 'red';
    }

    return (
        <p id="imageBiRadScore" className={`image-score ${scoreColorClass}`}>
            {value}
        </p>
    );
};


const ImageDetails = ({ laterality, viewPosition, imageDetails }) => {
    const { translations } = useContext(AppContext);

    if (!imageDetails) {
        return <div className="image-details">Image details not available</div>;
    }



    const StudyDate = imageDetails.StudyDate;
    const StudyTime = imageDetails.StudyTime;

    const year = parseInt(StudyDate.substr(0, 4));
    const month = parseInt(StudyDate.substr(4, 2)) - 1; // Months are 0-indexed in JavaScript
    const day = parseInt(StudyDate.substr(6, 2));

    const hours = parseInt(StudyTime.substr(0, 2));
    const minutes = parseInt(StudyTime.substr(2, 2));
    const seconds = parseInt(StudyTime.substr(4, 2));

    // Construct a new Date object with the extracted date and time components
    const dateTime = new Date(year, month, day, hours, minutes, seconds);

    const biRadScore = imageDetails?.biRads || "";

    return (
        <div className="image-details">
            <div className="image-row">
                <label className="image-label">Laterality: </label>
                <p className="image-text">{laterality}</p>
            </div>
            <div className="image-row">
                <label className="image-label">View Position: </label>
                <p className="image-text">{viewPosition}</p>
            </div>
            {dateTime && (
                <div className="image-row">
                    <label className="image-label">Date: </label>
                    <p className="image-text">{dateTime.toLocaleDateString()}</p>
                </div>
            )}
            {dateTime && (
                <div className="image-row">
                    <label className="image-label">Time: </label>
                    <p className="image-text">{dateTime.toLocaleTimeString()}</p>
                </div>
            )}
            {biRadScore && (
                <div className="image-row">
                    <label className="image-label">BI-Rad Score: </label>
                    <ImageScore value={biRadScore} />
                </div>
            )}
        </div>
    );
};



const ImageContainer = ({ laterality, viewPosition, imageDetails }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const Base64Image = imageDetails?.Base64Image;
    const awsS3URL = imageDetails?.AWS_S3_URL || "";
    let isImageUploaded = false;
    if (awsS3URL && awsS3URL !== "Pending to upload...") {
        isImageUploaded = true;
    }

    const buttonText = isImageUploaded ? "View" : "";

    let placeholder;
    if (laterality === "L" && viewPosition === "CC") {
        placeholder = L_CCImage;
    } else if (laterality === "L" && viewPosition === "MLO") {
        placeholder = L_MLOImage;
    } else if (laterality === "R" && viewPosition === "CC") {
        placeholder = R_CCImage;
    } else if (laterality === "R" && viewPosition === "MLO") {
        placeholder = R_MLOImage;
    }


    const handleImageClick = async () => {

        setIsModalOpen(true);

        /*if (isImageUploaded) {
            // If the image is uploaded, trigger the download functionality
            const link = document.createElement("a");
            link.href = awsS3URL;
            link.target = "_blank"; // Open the link in a new tab
            link.download = `${laterality}-${viewPosition}.dcm`; // Set the downloaded file's name

            // Programmatically click on the link to trigger the download
            link.click();
        }*/
    };


    return (
        <div
            className="image-container-screening"
            data-content={buttonText}
        >
            <button className="image-button" onClick={handleImageClick}>
                {Base64Image ? (
                    <img
                        id="image"
                        src={`data:image/jpeg;base64, ${Base64Image}`}
                        className="uploaded-image"
                    />
                ) : (
                    <img
                        id="image"
                        src={placeholder}
                        className="placeholder-image"
                    />
                )}
            </button>
            <ImageDetails laterality={laterality} viewPosition={viewPosition} imageDetails={imageDetails} />
            {isModalOpen && (
                <div className="modal-viewer">
                    <div className="modal-viewer-content">
                        <DicomViewer awsS3URL={awsS3URL} closeModal={toggleModal} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ImageContainer;


