import React, { useEffect, useState, useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import { APIGetAppointmentFacets } from '../../services/APIService';

const Checkbox = ({ id, value, label, onChange }) => {
    return (
        <li className="w-full">
            <div className="flex items-center pl-2">
                <input
                    id={id}
                    type="checkbox"
                    value={value}
                    onChange={onChange}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                />
                <label
                    htmlFor={id}
                    className="w-full pt-1 ml-2 text-sm font-normal text-gray-900 dark:text-gray-300"
                >
                    {label}
                </label>
            </div>
        </li>
    );
};

const Filters = ({ onFilterChange }) => {
    const [selectedFilters, setSelectedFilters] = useState({
        searchQuery: '',
        facets: [],
    });
    const [searchQuery, setSearchQuery] = useState('');
    const { translations, currentAppUser } = useContext(AppContext);
    const [showLocationCheckboxes, setShowLocationCheckboxes] = useState(false);
    const [numberSelectedCenters, setSelectedCenters] = useState(0);

    useEffect(() => {
        // Reset selectedFilters state
        setSelectedFilters({
            searchQuery: '',
            facets: [],
        });

        // Reset UI selection of checkboxes
        checkboxData.forEach(({ checkboxes }) => {
            checkboxes.forEach(({ id }) => {
                const checkboxElement = document.getElementById(id);
                if (checkboxElement) {
                    checkboxElement.checked = false;
                }
            });
        });

        // Reset search query
        setSearchQuery('');

    }, [currentAppUser]);

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        const updatedFilters = {
            ...selectedFilters,
            searchQuery: searchQuery && searchQuery.trim() !== '' ? searchQuery.trim() : undefined,
        };
        setSelectedFilters(updatedFilters);
        onFilterChange(updatedFilters);
        performSearch();
    };

    const performSearch = () => {
        const { facets } = selectedFilters;

        const updatedFilters = facets.filter((facet) => facet.value.length > 0);

        setSelectedFilters((prevSelectedFilters) => ({
            ...prevSelectedFilters,
            facets: updatedFilters,
        }));
    };

    const handleInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleLocationToggle = () => {
        setShowLocationCheckboxes(!showLocationCheckboxes);
    };

    const handleFilterChange = (filterCategory, filterValue) => {
        const { searchQuery, facets } = selectedFilters;

        // Find the facet in the facets array
        const facetIndex = facets.findIndex((facet) => facet.category === filterCategory);

        let updatedFacets;
        if (facetIndex === -1) {
            // If the facet doesn't exist, add it to the array with the selected value
            const newFacet = {
                category: filterCategory,
                value: [filterValue],
            };
            updatedFacets = [...facets, newFacet];
        } else {
            // If the facet exists, update its value array
            updatedFacets = facets.map((facet, index) => {
                if (index === facetIndex) {
                    const updatedValue = facet.value.includes(filterValue)
                        ? facet.value.filter((value) => value !== filterValue)
                        : [...facet.value, filterValue];

                    // Remove the facet if all values are unselected
                    if (updatedValue.length === 0) {
                        return undefined;
                    }

                    return {
                        ...facet,
                        value: updatedValue,
                    };
                }
                return facet;
            });
        }

        const filteredFacets = updatedFacets.filter((facet) => facet !== undefined);

        const updatedFilters = {
            searchQuery: searchQuery && searchQuery.trim() !== '' ? searchQuery.trim() : undefined,
            facets: filteredFacets,
        };

        setSelectedFilters(updatedFilters);
        onFilterChange(updatedFilters);
        countSelectedCenters(filteredFacets);
    };

    const renderCheckboxes = (checkboxes) => {
        return checkboxes.map((checkbox) => {
            const label = checkbox.filterCategory === 'center' ? checkbox.label : translations.filters[checkbox.label];
            return (
                <Checkbox
                    key={checkbox.id}
                    id={checkbox.id}
                    value={checkbox.value}
                    label={label}
                    onChange={() => handleFilterChange(checkbox.filterCategory, checkbox.filterValue)}
                />
            );
        });
    };


    const countSelectedCenters = (facets) => {
        const locationFilters = facets?.find((filter) => filter.category === 'center');
      
        if (locationFilters) {
          const selectedCenters = locationFilters.value.length;
          setSelectedCenters(selectedCenters);
        } else {
          setSelectedCenters(0);
        }
      };

    const checkboxLocationData = [
        {
            category: "Center",
            checkboxes: [
                { id: 'st-alma-medical-center-checkbox', value: 'St.Alma medical center', label: 'St.Alma medical center', filterCategory: 'center', filterValue: 'St.Alma medical center' },
                { id: 'st-alondra-clinic-checkbox', value: 'St.Alondra clinic', label: 'St.Alondra clinic', filterCategory: 'center', filterValue: 'St.Alondra clinic' },
                { id: 'st-amelia-hospital-checkbox', value: 'St.Amelia hospital', label: 'St.Amelia hospital', filterCategory: 'center', filterValue: 'St.Amelia hospital' },
                { id: 'st-ana-maria-clinic-checkbox', value: 'St.Ana María clinic', label: 'St.Ana María clinic', filterCategory: 'center', filterValue: 'St.Ana María clinic' },
                { id: 'st-antonio-clinic-checkbox', value: 'St.Antonio clinic', label: 'St.Antonio clinic', filterCategory: 'center', filterValue: 'St.Antonio clinic' },
                { id: 'st-caridad-medical-center-checkbox', value: 'St.Caridad medical center', label: 'St.Caridad medical center', filterCategory: 'center', filterValue: 'St.Caridad medical center' },
                { id: 'st-cruz-clinic-checkbox', value: 'St.Cruz clinic', label: 'St.Cruz clinic', filterCategory: 'center', filterValue: 'St.Cruz clinic' },
                { id: 'st-david-clinic-checkbox', value: 'St.David clinic', label: 'St.David clinic', filterCategory: 'center', filterValue: 'St.David clinic' },
                { id: 'st-dayana-hospital-checkbox', value: 'St.Dayana hospital', label: 'St.Dayana hospital', filterCategory: 'center', filterValue: 'St.Dayana hospital' },
                { id: 'st-diana-clinic-checkbox', value: 'St.Diana clinic', label: 'St.Diana clinic', filterCategory: 'center', filterValue: 'St.Diana clinic' },
                { id: 'st-emma-clinic-checkbox', value: 'St.Emma clinic', label: 'St.Emma clinic', filterCategory: 'center', filterValue: 'St.Emma clinic' },
                { id: 'st-emelda-clinic-checkbox', value: 'St.Emelda clinic', label: 'St.Emelda clinic', filterCategory: 'center', filterValue: 'St.Emelda clinic' },
                { id: 'st-estefania-clinic-checkbox', value: 'St.Estefanía clinic', label: 'St.Estefanía clinic', filterCategory: 'center', filterValue: 'St.Estefanía clinic' },
                { id: 'st-fatima-hospital-checkbox', value: 'St.Fatima hospital', label: 'St.Fatima hospital', filterCategory: 'center', filterValue: 'St.Fatima hospital' },
                { id: 'st-gonzalo-hospital-checkbox', value: 'St.Gonzalo hospital', label: 'St.Gonzalo hospital', filterCategory: 'center', filterValue: 'St.Gonzalo hospital' },
                { id: 'st-ines-hospital-checkbox', value: 'St.Inés hospital', label: 'St.Inés hospital', filterCategory: 'center', filterValue: 'St.Inés hospital' },
                { id: 'st-inma-clinic-checkbox', value: 'St.Inma clinic', label: 'St.Inma clinic', filterCategory: 'center', filterValue: 'St.Inma clinic' },
                { id: 'st-iker-medical-center-checkbox', value: 'St.Iker medical center', label: 'St.Iker medical center', filterCategory: 'center', filterValue: 'St.Iker medical center' },
                { id: 'st-jair-clinic-checkbox', value: 'St.Jair clinic', label: 'St.Jair clinic', filterCategory: 'center', filterValue: 'St.Jair clinic' },
                { id: 'st-jon-clinic-checkbox', value: 'St.Jon clinic', label: 'St.Jon clinic', filterCategory: 'center', filterValue: 'St.Jon clinic' },
                { id: 'st-josep-medical-center-checkbox', value: 'St.Josep medical center', label: 'St.Josep medical center', filterCategory: 'center', filterValue: 'St.Josep medical center' },
                { id: 'st-juan-felipe-medical-center-checkbox', value: 'St.Juan Felipe medical center', label: 'St.Juan Felipe medical center', filterCategory: 'center', filterValue: 'St.Juan Felipe medical center' },
                { id: 'st-juan-jose-hospital-checkbox', value: 'St.Juan José hospital', label: 'St.Juan José hospital', filterCategory: 'center', filterValue: 'St.Juan José hospital' },
                { id: 'st-juan-sebastian-hospital-checkbox', value: 'St.Juan Sebastián hospital', label: 'St.Juan Sebastián hospital', filterCategory: 'center', filterValue: 'St.Juan Sebastián hospital' },
                { id: 'st-leonardo-clinic-checkbox', value: 'St.Leonardo clinic', label: 'St.Leonardo clinic', filterCategory: 'center', filterValue: 'St.Leonardo clinic' },
                { id: 'st-lidia-clinic-checkbox', value: 'St.Lidia clinic', label: 'St.Lidia clinic', filterCategory: 'center', filterValue: 'St.Lidia clinic' },
                { id: 'st-lucha-clinic-checkbox', value: 'St.Lucha clinic', label: 'St.Lucha clinic', filterCategory: 'center', filterValue: 'St.Lucha clinic' },
                { id: 'st-lupita-hospital-checkbox', value: 'St.Lupita hospital', label: 'St.Lupita hospital', filterCategory: 'center', filterValue: 'St.Lupita hospital' },
                { id: 'st-luz-clinic-checkbox', value: 'St.Luz clinic', label: 'St.Luz clinic', filterCategory: 'center', filterValue: 'St.Luz clinic' },
                { id: 'st-marjun-medical-center-checkbox', value: 'St.Marjun medical center', label: 'St.Marjun medical center', filterCategory: 'center', filterValue: 'St.Marjun medical center' },
                { id: 'st-martin-medical-center-checkbox', value: 'St.Martín medical center', label: 'St.Martín medical center', filterCategory: 'center', filterValue: 'St.Martín medical center' },
                { id: 'st-maria-medical-center-checkbox', value: 'St.María medical center', label: 'St.María medical center', filterCategory: 'center', filterValue: 'St.María medical center' },
                { id: 'st-matthew-clinic-checkbox', value: 'St.Matthew clinic', label: 'St.Matthew clinic', filterCategory: 'center', filterValue: 'St.Matthew clinic' },
                { id: 'st-matthew-medical-center-checkbox', value: 'St.Matthew medical center', label: 'St.Matthew medical center', filterCategory: 'center', filterValue: 'St.Matthew medical center' },
                { id: 'st-meagens-medical-center-checkbox', value: 'St.Meagens medical center', label: 'St.Meagens medical center', filterCategory: 'center', filterValue: 'St.Meagens medical center' },
                { id: 'st-mercedes-medical-center-checkbox', value: 'St.Mercedes medical center', label: 'St.Mercedes medical center', filterCategory: 'center', filterValue: 'St.Mercedes medical center' },
                { id: 'st-neron-hospital-checkbox', value: 'St.Neron hospital', label: 'St.Neron hospital', filterCategory: 'center', filterValue: 'St.Neron hospital' },
                { id: 'st-neron-medical-center-checkbox', value: 'St.Neron medical center', label: 'St.Neron medical center', filterCategory: 'center', filterValue: 'St.Neron medical center' },
                { id: 'st-paula-clinic-checkbox', value: 'St.Paula clinic', label: 'St.Paula clinic', filterCategory: 'center', filterValue: 'St.Paula clinic' },
                { id: 'st-rocio-clinic-checkbox', value: 'St.Rocío clinic', label: 'St.Rocío clinic', filterCategory: 'center', filterValue: 'St.Rocío clinic' },
                { id: 'st-roxana-hospital-checkbox', value: 'St.Roxana hospital', label: 'St.Roxana hospital', filterCategory: 'center', filterValue: 'St.Roxana hospital' },
                { id: 'st-rut-medical-center-checkbox', value: 'St.Rut medical center', label: 'St.Rut medical center', filterCategory: 'center', filterValue: 'St.Rut medical center' },
                { id: 'st-santiago-medical-center-checkbox', value: 'St.Santiago medical center', label: 'St.Santiago medical center', filterCategory: 'center', filterValue: 'St.Santiago medical center' },
                { id: 'st-valentina-medical-center-checkbox', value: 'St.Valentina medical center', label: 'St.Valentina medical center', filterCategory: 'center', filterValue: 'St.Valentina medical center' },
                { id: 'st-xalvador-medical-center-checkbox', value: 'St.Xalvador medical center', label: 'St.Xalvador medical center', filterCategory: 'center', filterValue: 'St.Xalvador medical center' },
                { id: 'st-ximena-medical-center-checkbox', value: 'St.Ximena medical center', label: 'St.Ximena medical center', filterCategory: 'center', filterValue: 'St.Ximena medical center' },
                { id: 'st-adrian-medical-center-checkbox', value: 'St.adrian medical center', label: 'St.adrian medical center', filterCategory: 'center', filterValue: 'St.adrian medical center' },
                { id: 'st-guille-medical-center-checkbox', value: 'St.guille medical center', label: 'St.guille medical center', filterCategory: 'center', filterValue: 'St.guille medical center' }
            ]
        }
    ]

    const checkboxData = [
        {
            category: translations.filters.apptTime,
            checkboxes: [
                { id: 'today-checkbox', value: 'today', label: 'today', filterCategory: 'period', filterValue: 'today' },
                { id: 'week-checkbox', value: 'week', label: 'thisWeek', filterCategory: 'period', filterValue: 'week' },
                { id: 'month-checkbox', value: 'month', label: 'thisMonth', filterCategory: 'period', filterValue: 'month' },
            ],
        },
        {
            category: translations.filters.apptStatus,
            checkboxes: [
                { id: 'fulfilled-checkbox', value: 'fulfilled', label: 'fulfilled', filterCategory: 'status', filterValue: 'fulfilled' },
                { id: 'proposed-checkbox', value: 'proposed', label: 'proposed', filterCategory: 'status', filterValue: 'proposed' },
                { id: 'booked-checkbox', value: 'booked', label: 'booked', filterCategory: 'status', filterValue: 'booked' },
                /*{ id: 'arrived-checkbox', value: 'arrived', label: 'arrived', filterCategory: 'status', filterValue: 'arrived' },*/
                { id: 'noShow-checkbox', value: 'noshow', label: 'noShow', filterCategory: 'status', filterValue: 'noshow' },
                { id: 'canceled-checkbox', value: 'canceled', label: 'canceled', filterCategory: 'status', filterValue: 'canceled' },
                /*{ id: 'waitlist-checkbox', value: 'Waitlist', label: 'waitlist', filterCategory: 'status', filterValue: 'waitlist' },*/
            ],
        },
        {
            category: translations.filters.ageRange,
            checkboxes: [
              /*  { id: 'less-than-50-checkbox', value: '49', label: 'lessThan50', filterCategory: 'age', filterValue: '49' },*/
                { id: '50-to-60-checkbox', value: '50-60', label: '50to60', filterCategory: 'age', filterValue: '50-59' },
                { id: '60-70-checkbox', value: '60-69', label: '60to70', filterCategory: 'age', filterValue: '60-69' },
                { id: 'more-than-70-checkbox', value: '70', label: 'moreThan70', filterCategory: 'age', filterValue: '70' },
            ],
        },
        /* {
           category: translations.filters.resultsBIRADSScore,
            checkboxes: [
                { id: '1-checkbox', value: '', label: 'birads1' },
                { id: '2-checkbox', value: '', label: 'birads2' },
                { id: '3-checkbox', value: '', label: 'birads3' },
                { id: '4-checkbox', value: '', label: 'birads4' },
                { id: '5-checkbox', value: '', label: 'birads5' },
                { id: '6-checkbox', value: '', label: 'birads6' },
            ],
        },*/
    ];

    return (
        <div className="ml-10 shadow-sm rounded-lg bg-white">
            <form onSubmit={handleSearchSubmit} className="pt-2 px-2 flex space-x-2">
                {/* Search input */}
                <input
                    value={searchQuery}
                    className="px-2 py-1 rounded shadow-sm"
                    placeholder="Search by Name, ID, etc."
                    type="text"
                    onChange={handleInputChange}
                />
                <button
                    type="submit"
                    className="px-4 py-1 text-sm text-green-700 font-semibold rounded-full border border-gray-200 hover:text-white hover:bg-green-800 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-green-800 focus:ring-offset-2"
                >
                    Search
                </button>
            </form>
            <div className="pt-4 ml-3">
                {currentAppUser !== 'hc-provider' && (
                    <form>
                        <div className="select-wrapper">
                            {/* Select control */}
                            Select Centers<select className="dropdown-toggle font-medium mb-3" onClick={handleLocationToggle}>

                            </select>{numberSelectedCenters > 0 && `(${numberSelectedCenters}) selected`}

                            {/* Dropdown options */}
                            {showLocationCheckboxes && (
                                <div className="dropdown-options list-none">
                                    {checkboxLocationData.map((item) => (
                                        <div key={item.category}>
                                            <ul className="w-48 text-sm font-medium text-gray-900 bg-white rounded-lg dark:text-white">
                                                {renderCheckboxes(item.checkboxes)}
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </form>
                )}
                {/* Render checkboxes */}
                {checkboxData.map((item) => (
                    <div key={item.category}>
                        <p className="mb-2 font-medium text-s text-gray-900 dark:text-white">
                            {item.category}
                        </p>
                        <ul className="w-48 text-sm font-medium text-gray-900 bg-white rounded-lg dark:text-white">
                            {renderCheckboxes(item.checkboxes)}
                        </ul>
                    </div>
                ))}
            </div>
        </div >
    );
};


export default Filters;


