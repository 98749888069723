import React, { useContext, useRef, useEffect, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import close from './img/close.png';
import './InspectConsoleNew.css';
import plus from './img/plus.png';
import minus from './img/minus.png';

function InspectConsoleNew({ onClose, activityLog }) {
  const logContainerRef = useRef(null);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [expandedCodeBlockIndex, setExpandedCodeBlockIndex] = useState(null);

  useEffect(() => {
    // Scroll to the bottom of the log container when a new log entry is added
    logContainerRef.current?.scrollTo(0, logContainerRef.current?.scrollHeight);
  }, [activityLog]);

  const toggleExpand = (index) => {
    if (index === expandedIndex) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };

  const toggleExpandCodeBlock = (index) => {
    if (index === expandedCodeBlockIndex) {
      setExpandedCodeBlockIndex(null);
    } else {
      setExpandedCodeBlockIndex(index);
    }
  };

  const getExecutionTime = (startTime, endTime) => {
    const start = new Date(startTime);
    const end = new Date(endTime);
    const executionTime = end.getTime() - start.getTime();
    return `${executionTime}ms`;
  };

  return (
    <div className="inspector">

      {/* Header */}
      <div className="flex items-center justify-between px-4 py-1 bg-gray-900">
        <h1 className="text-white text-lg font-semibold ml-14">Inspect Console</h1>
        <button
          onClick={onClose}
          className="text-white rounded-full bg-white px-3 py-1 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
        >
          <div className="flex items-center px-1">
            <img className="h-4 w-4 rounded-full" src={close} alt="" />
            <div className="text-left ml-1">
              <p className="mb-0 text-red-500">Close</p>
            </div>
          </div>
        </button>
      </div>
      <div ref={logContainerRef} className="logsContainer overflow-auto max-h-full pt-2 pb-4 mx-20">
        {activityLog
          .slice(-20) // Limit to the last 20 transactions
          .map((transaction, index) => (
            <div key={transaction.id}>
              <div className={`transaction-header ${expandedIndex === index ? 'expanded' : ''} text-white`}>
                <button className="expand-button" onClick={() => toggleExpand(index)}>
                  {expandedIndex === index ? (

                    <>
                      <img src={minus} alt="Plus" className="w-5 h-5  -mt-7 mr-1" />

                    </>
                  ) : (

                    <>
                      <img src={plus} alt="Plus" className="w-5 h-5  -mt-7 mr-1" />

                    </>

                  )}
                </button>
                <div>
                  <h2 className="text-xl font-bold">{transaction.title}</h2>
                  <p className="text-gray-300 text-xs">
                    Start Time: {new Date(transaction.startTime).toLocaleString()}
                  </p>
                </div>
              </div>
              {expandedIndex === index && (
                <>
                  {transaction.logEntries.map((entry, entryIndex) => (
                    <div key={entryIndex}>
                      <p className="font-medium text-white">{entry.message}</p>
                      <p className="text-gray-400 text-xs">
                        {new Date(entry.timestamp).toLocaleString()}
                      </p>
                      {expandedCodeBlockIndex === index && entry.data !== null ? (
                        <>
                          <p className="font-medium text-white">Data: </p>
                          <pre className="bg-gray-800 text-pink-500 rounded-xlpx-4 py-2 w-90">
                            {JSON.stringify(entry.data, null, 2)}
                          </pre>
                          <div className="read-less-container">
                            <button
                              className="text-green-400 text-xs mb-4 mt-0 underline"
                              onClick={() => toggleExpandCodeBlock(index)}
                            >
                              Show less
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <p className="font-medium text-white">
                            Data: <code>{JSON.stringify(entry.data).slice(0, 100)}... </code>
                          </p>
                          <div className="read-more-container underline">
                            <button
                              className="text-green-400 mt-0 mb-4 text-xs underline"
                              onClick={() => toggleExpandCodeBlock(index)}
                            >
                              Show more
                            </button>
                          </div>
                        </>
                      )}
                      {entry.style !== 'code-request' && (
                        <p className="pt-0 pb-4 text-white text-sm">
                          Execution Time: {getExecutionTime(transaction.startTime, entry.timestamp)}
                        </p>
                      )}
                    </div>
                  ))}
                </>
              )}
              <hr className="mt-1 mb-4 border-gray-300" />
            </div>
          ))}
      </div>

    </div>
  );

}

export default InspectConsoleNew;
