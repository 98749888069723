import React from 'react';

function Kpis(props) {
  const { percentage } = props;
  let formattedKpi;

  if (percentage) {
    if (props.kpi === 100) {
      formattedKpi = '100%';
    } else {
      formattedKpi = `${props.kpi.toFixed(2)}%`;
    }
  } else {
    formattedKpi = props.kpi;
  }

  return (
    <div className="mb-2 py-8 px-8 max-w-sm mx-3 bg-white rounded-xl shadow-md space-y-2 sm:py-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6">
      <div className="text-center space-y-2 sm:text-left">
        <div className="space-y-0.5">
          <p className="text-sm text-black font-semibold">{props.title}</p>
          <p className="text-lg text-slate-500 font-medium">
            {formattedKpi || 'No Data Available'}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Kpis;
