import React, { useState, useEffect, useRef, useContext } from 'react';
import Screening from '../Screening/Screening';
import { APILoadInitialAppointments, APILoadMoreAppointments, APIGetClinicalReport, APILoadPage } from '../../services/APIService';
import patientPhoto from './img/patient.png';
import { AppContext } from '../../context/AppContext';
import { Modal } from 'react-bootstrap';
import ReportCard from '../ReportCard/ReportCard';

function Appointments({ selectedFilters }) {
  const [showModalScreening, setShowModalScreening] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const tableRef = useRef(null);
  const { preferences, currentAppUser, currentCenter } = useContext(AppContext)
  const [selectedReport, setSelectedReport] = useState(null);
  const [expandedAppointmentId, setExpandedAppointmentId] = useState(null);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  
  const handleShowScreening = (appointment) => {
    setSelectedAppointment(appointment);
    setShowModalScreening(true);
  };

  const handleCloseScreening = () => {
    setShowModalScreening(false);  };


    const handleResultsShow = async (appointment) => {
      setSelectedAppointment(appointment);
    
      try {
        const appointmentId = appointment._id;
        const report = await APIGetClinicalReport(appointmentId, preferences, currentAppUser);
        setSelectedReport(report);
    
        // Toggle expanded appointment ID
        setExpandedAppointmentId(prevId => (prevId === appointmentId ? null : appointmentId));
      } catch (error) {
        setExpandedAppointmentId(null);
        console.error('Error fetching clinical report:', error);
      }
    };
    

  const handleResultsClose = () => {
    setExpandedAppointmentId(null); // Reset the expanded appointment ID
    setSelectedReport(null); // Clear the selected report
  };


  useEffect(() => {
    setLoading(true);
    APILoadInitialAppointments(selectedFilters, sortField, sortOrder, preferences, currentAppUser, currentCenter)
      .then(({ initialAppointments, nextLink, prevLink }) => {
        setAppointments(initialAppointments);
        setNextUrl(nextLink ? nextLink.url : null);
        setPrevUrl(prevLink ? prevLink.url : null);
      })
      .catch((error) => {
        console.error('Error loading initial appointments:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedFilters, sortField, sortOrder, currentAppUser, currentCenter, preferences]);


  const handleLoadMoreAppointments = async () => {
    setLoading(true);
      try {
        const filteredAppointments = await APILoadMoreAppointments(appointments, selectedFilters, sortField, sortOrder, preferences, currentAppUser, currentCenter);

        setAppointments((prevAppointments) => [...prevAppointments, ...filteredAppointments]);
      } catch (error) {
        // Handle error
        console.error('Error loading more appointments:', error);
      }
    
    setLoading(false);
  };

  const handlePages = async (URL) => {
    setLoading(true);
    try {
      const { nextAppointments = [], nextLink = null, prevLink = null } = await APILoadPage(URL,preferences, currentAppUser);
      setAppointments(nextAppointments);
      setNextUrl(nextLink ? nextLink.url : null);
      setPrevUrl(prevLink ? prevLink.url : null);

    } catch (error) {
      console.error('Error loading new appointments:', error);
    } finally {
      setLoading(false);
    }
  };
  

  const handleSort = (field) => {
    if (field === sortField) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };


  const isTodayOrTomorrowAppointment = (start) => {
    if (!start) {
      return false;
    }

    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    const appointmentDate = new Date(start);
    const appointmentDateString = appointmentDate.toDateString();

    return (
      appointmentDateString === today.toDateString() ||
      appointmentDateString === tomorrow.toDateString()
    );
  };

  function getStatusColor(status) {
    switch (status) {
      case 'booked':
        return 'text-green-900';
      case 'noshow':
        return 'text-red-900';
      case 'canceled':
        return 'text-purple-900';
      case 'fulfilled':
        return 'text-blue-900';
      case 'proposed':
        return 'text-gray-900';
      case 'arrived':
        return 'text-orange-900';
      case 'waitlist':
        return 'text-yellow-900';
      default:
        return 'text-gray-900';
    }
  }

  function getStatusBackgroundColor(status) {
    switch (status) {
      case 'booked':
        return 'bg-green-200';
      case 'noshow':
        return 'bg-red-200';
      case 'canceled':
        return 'bg-purple-200';
      case 'fulfilled':
        return 'bg-blue-200';
      case 'proposed':
        return 'bg-gray-200';
      case 'arrived':
        return 'bg-orange-200';
      case 'waitlist':
        return 'bg-yellow-200';
      default:
        return 'bg-gray-200';
    }
  }

  const calculateAge = (birthDate) => {
    const today = new Date();
    const birth = new Date(birthDate);
    
    let age = today.getFullYear() - birth.getFullYear();
    const monthDifference = today.getMonth() - birth.getMonth();
    
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birth.getDate())
    ) {
      age--;
    }
    
    return age;
  };
  


  return (
    <>
      <table className="table-auto" ref={tableRef} style={{ maxHeight: '500px', overflowY: 'scroll' }}>
        <thead>
          <tr>
            <th className="pl-4 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">PHOTO</th>
            <th className="pl-4 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" onClick={() => handleSort('name')}> NAME {sortField === 'name' && (sortOrder === 'asc' ? '↑' : '↓')} </th>
            <th className="pl-5 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" onClick={() => handleSort('age')}>AGE
              {sortField === 'age' && (<span className="ml-1">{sortOrder === 'asc' ? <>&uarr;</> : <>&darr;</>}</span>)}
            </th>
            <th className="pl-5 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" onClick={() => handleSort('status')}>STATUS
              {sortField === 'status' && (<span className="ml-1">{sortOrder === 'asc' ? <>&uarr;</> : <>&darr;</>}</span>)}
            </th>
            <th className="ml-4 pl-4 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" onClick={() => handleSort('start')}>APPT. DATE
              {sortField === 'start' && (<span className="ml-1">{sortOrder === 'asc' ? <>&uarr;</> : <>&darr;</>}</span>)}
            </th>
            <th className="pl-5 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" onClick={() => handleSort('institution')}>MEDICAL CENTER
              {sortField === 'institution' && (<span className="ml-1">{sortOrder === 'asc' ? <>&uarr;</> : <>&darr;</>}</span>)}
            </th>
            <th className="pl-5 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">PRACTITIONER</th>
            <th className="pl-5 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">ACTIONS</th>
          </tr>
        </thead>
        <tbody>
        {Array.isArray(appointments) &&
    appointments.map((appointment) => {

      const isFulfilled = appointment.status === 'fulfilled';
      const isBooked = appointment.status === 'booked';
      const isTodayOrTomorrow = isTodayOrTomorrowAppointment(appointment.start);
      const patientAge = calculateAge(appointment.subject.birthDate);
      const isExpanded = expandedAppointmentId === appointment._id;

              return (
                <React.Fragment key={appointment._id}>
                  <tr key={appointment._id} className="px-5 py-5 bg-white rounded-lg border-b border-gray-200 bg-white shadow-md text-sm">
                    <td className="pl-4 py-2">
                      <img alt="patient" src={patientPhoto} />
                    </td>
                    <td className="pl-4 py-2 ">{appointment.subject?.display?.[0]?.given?.[0] || '******'} {appointment.subject?.display?.[0]?.family || ' '}</td>
                    <td className="pl-5 py-2">{patientAge}</td>
                    <td className="pl-5 py-2">
                      <span className={`relative inline-block px-3 py-1 font-semibold leading-tight ${getStatusColor(appointment.status)}`}>
                        <span aria-hidden className={`absolute inset-0 opacity-50 rounded-full ${getStatusBackgroundColor(appointment.status)}`}></span>
                        <span className="relative">{appointment.status}</span>
                      </span>
                    </td>
                    <td className="ml-4 pl-4 py-2">{appointment.start.toLocaleString()}</td>
                    <td className="pl-5 py-2">{appointment.location.display}</td>
                    <td className="pl-5 py-2 ">{appointment.participant?.[0]?.actor?.display?.[0]?.given?.[0]} {appointment.participant?.[0]?.actor?.display?.[0]?.family}</td>

                    <td className="pl-5 pr-3 py-2">
                      {isBooked && isTodayOrTomorrow && (
                        <button
                          onClick={() => handleShowScreening(appointment)}
                          className="px-4 py-1 text-sm text-purple-600 font-semibold rounded-full border border-gray-200 hover:text-white hover:bg-purple-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2"
                        >
                          Start
                        </button>
                      )}
                      {isFulfilled && (
                        <button
                          onClick={() => handleResultsShow(appointment)}
                          className="px-3 py-1 text-sm text-gray-600 font-semibold rounded-full border border-gray-200 hover:text-white hover:bg-gray-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-offset-2"
                        >
                          {expandedAppointmentId === appointment._id ? 'Close' : 'Results'}
                        </button>
                      )}
                    </td>
                  </tr>
                  {/* Expanded Report Card Row */}
                  {isExpanded && (
                    <tr className="px-5 py-5 bg-white rounded-lg border-b border-gray-200 bg-white shadow-md text-sm">
                      <td colSpan="8">
                        <ReportCard 
                          patientName={appointment.subject?.display?.[0]?.given?.[0] || '******'}
                          patientLastName={appointment.subject?.display?.[0]?.family || '******'}                          
                          patientID={appointment.participant[0]?.actor?.id}
                          appointmentID={appointment._id}
                          score={null}
                          conclusions={selectedReport.conclusions}
                          clinicalNotes={selectedReport.clinicalNotes}
                          series={selectedReport.series}
                          date={selectedReport.date}
                          displayExpandedRow={true}
                        />
                      </td>
                    </tr>
                  )}
                 </React.Fragment>
              );
            })}
        </tbody>
      </table>
      {loading && <p className="italic mt-2">Loading...</p>}
      {showModalScreening && selectedAppointment && (
        <Screening
          handleClose={handleCloseScreening}
          appointment={selectedAppointment}
        />
      )}
      <div className="flex justify-center mt-4 mb-8">
        <div className="mx-4">
          {/* Render either "Load more appointments" or "Next Page" and "Previous Page" buttons */}
          {preferences.dataSets[0].screening.selected.HCProviderApi === 'MongoDB' ? (
            // Render "Load more appointments" button
            <button
              onClick={handleLoadMoreAppointments}
              className="px-4 py-1 border border-black text-gray-400 rounded-full hover:text-white hover:bg-gray-400 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2"
            >
              Load more appointments
            </button>
          ) : (
            // Render "Next Page" and "Previous Page" buttons
            <>
              {prevUrl && (
                <button
                  onClick={() => handlePages(prevUrl)}
                  className="mx-2 px-4 py-1 border border-black text-gray-400 rounded-full hover:text-white hover:bg-gray-400 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2"
                >
                  Previous Page
                </button>
              )}

              {nextUrl && (
                <button
                onClick={() => handlePages(nextUrl)}
                  className="mx-2 px-4 py-1 border border-black text-gray-400 rounded-full hover:text-white hover:bg-gray-400 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2"
                >
                  Next Page
                </button>
              )}
            </>
          )}
          <div className="flex justify-center mt-2">
            <p className="text-sm text-gray-600">
              {appointments.length} appointments loaded
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Appointments;