import React, { useState, useContext, useEffect } from 'react';
import './ScreeningList.css';
import Kpis from '../../components/Kpis/Kpis';
import Appointments from '../../components/Appointments/Appointments';
import Filters from '../../components/Filters/Filters';
import { AppContext } from '../../context/AppContext';

import { APICountAppointments } from '../../services/APIService';

function ScreeningList() {
  const [selectedFilters, setSelectedFilters] = useState({});
  const { translations } = useContext(AppContext);
  const { activityLog, setActivityLog } = useContext(AppContext);
  const { preferences, currentAppUser, currentCenter } = useContext(AppContext);
  const showPatients = true;
  const [totalAppointmentsCount, setTotalAppointmentsCount] = useState(0);
  const [selectedAppointmentsCount, setSelectedAppointmentsCount] = useState(0);
  const [appointmentSelectionPercentage, setAppointmentSelectionPercentage] = useState(0);

  const handleFilterChange = (filters) => {
    setSelectedFilters(filters);
  };

  const fetchAppointmentCounts = async () => {
    try {
      const totalAppointments = await APICountAppointments(
        selectedFilters,
        false,
        preferences,
        currentAppUser,
        currentCenter
      );
      const selectedAppointments = await APICountAppointments(
        selectedFilters,
        true,
        preferences,
        currentAppUser,
        currentCenter
      );

      setTotalAppointmentsCount(totalAppointments);
      setSelectedAppointmentsCount(selectedAppointments);
      setAppointmentSelectionPercentage((selectedAppointments / totalAppointments) * 100);
    } catch (error) {
      console.error('Error fetching appointment counts:', error);
    }
  };

  useEffect(() => {
    fetchAppointmentCounts();
  }, [selectedFilters, currentAppUser, currentCenter, preferences]);

  return (
    <div className="page">
      <div className="column-left">
        <div className="filters">
          <Filters onFilterChange={handleFilterChange} />
        </div>
      </div>
      <div className="column-right">
        <div className="kpis">
          <div className="flex justify-between">
            {showPatients ? (
              <>
                <Kpis title={translations.kpis.totalScheduledAppointments} kpi={totalAppointmentsCount} percentage={false} />
                <Kpis title={translations.kpis.numberofAppointmentsinselection} kpi={selectedAppointmentsCount} percentage={false} />
                <Kpis title={translations.kpis.appointmentSelectionPercentage} kpi={appointmentSelectionPercentage} percentage={true} />
              </>
            ) : (
              <p>There are no available patients</p>
            )}
          </div>
        </div>
        <div className="appointmentsList">
          <div className="appointment-wrapper">
            <Appointments selectedFilters={selectedFilters} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScreeningList;
