import React, { useContext, useState } from 'react';
import axios from 'axios';
import { AppContext } from '../../context/AppContext';
//import './AdvancedSearch.css';
import { Container } from 'react-bootstrap';
import ReportCard from '../../components/ReportCard/ReportCard'
import Alert from '../../components/Alert/Alert'
import { APIGenerateAndGetVector, APIVectorSearchReports } from '../../services/APIService';
import info from "./img/info.png";
import info_vector from "./img/info_vector.png";

export default function AdvancedSearch() {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const { preferences } = useContext(AppContext)
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { translations } = useContext(AppContext);


  //Info pop-up
  const [showInfoPopup, setShowInfoPopup] = useState(false);

  const handleInfoButtonClick = () => {
    setShowInfoPopup(true);
  };

  const handlePopupClose = () => {
    setShowInfoPopup(false);
  };


  const handleSearch = async () => {
    try {
      setLoading(true);
      const vectorizedPrompt = await APIGenerateAndGetVector(query, preferences);

      APIVectorSearchReports(vectorizedPrompt, preferences)
        .then((matchingResults) => {
          setResults(matchingResults);
        })
        .catch((error) => {
          console.error('Error executing the search in Atlas:', error);
        });
      setError(false);
    } catch (error) {
      console.error(error);
      setError(true);
    }
    setLoading(false);
  };

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    handleSearch();
  };

  const handlePromptClick = (promptText) => {
    setQuery(promptText);
  };

  const handleAlertClose = () => {
    setError(false);
  };



  return (
    <Container style={{ paddingBottom: '100px' }}>
      <div>
        {error && (
          <Alert
            title={translations.alert.error}
            message={translations.alert.failedToRetrieveData}
            onClose={handleAlertClose}
          />
        )}
      </div>
      <div className="flex justify-center items-center">
        <div className="w-full">
          <form onSubmit={handleSearchSubmit} className="mb-10 flex space-x-2">
            <input
              type="text"
              value={query}
              className="px-5 py-1 w-full rounded-lg shadow-sm resize-none"
              placeholder={translations.advancedSearch.typePrompt}
              onChange={handleInputChange}
            />
            <button
              type="submit"
              className="px-4 py-1 text-sm text-green-700 font-semibold rounded-full border border-gray-200 hover:text-white hover:bg-green-800 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-green-800 focus:ring-offset-2"
            >
              {translations.advancedSearch.search}
            </button>
          </form>
        </div>


      </div>

      <div className="flex justify-center items-center mb-4 mt-0">
        <button
          type="button"
          onClick={handleInfoButtonClick}
          className="text-gray-600 rounded-full border border-gray-200 hover:bg-purple-200"
        >
          <img src={info} alt="Info" className="h-5 w-5" />
        </button>
      </div>


      <div className="flex justify-center items-center">
        <div className="items-center">
          <p className="text-xs flex justify-center">{translations.advancedSearch.suggestedSearches}</p>
          {preferences.advancedSearch.promptExamples.map((prompt, index) => (
            <button
              key={index}
              className="mt-1 mx-2 px-3 py-1 text-xs text-green-700 font-semibold rounded-full border border-gray-200 hover:text-white hover:bg-green-800 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-green-800 focus:ring-offset-2"
              onClick={() => handlePromptClick(prompt)}
            >
              {prompt}
            </button>
          ))}
        </div>
      </div>
      <div>
        <div>
          {loading && <p className="italic mt-2">{translations.general.loading}</p>}
          {results.map((result, index) => (
            <ReportCard
              key={index}
              patientName={result['user']}
              patientID={result['patient-id']}
              appointmentID={result['appointment-id']}
              score={result['score']}
              conclusions={result['conclusions']}
              clinicalNotes={result['clinicalNotes']}
              series={result['series']}
              date={result['date']}
            />
          ))}
        </div>
      </div>

      {showInfoPopup && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10"
          onClick={handlePopupClose}
        >
          <div className="bg-white mx-6 p-2 rounded-lg shadow-lg w-50% z-10">
          <h2 className="text-center">NLP Search Architecture</h2>
            <img src={info_vector} alt="Info" className="h-50% w-50% object-contain mx-auto z-2" />
          </div>
        </div>
      )}
    </Container>
  );
}



















