import * as Realm from 'realm-web';
import { logActivity } from './LoggerService';
import axios from 'axios';

let authToken = null; // Initialize the auth bearer token as null
const setAuthToken = (Token) => {
  authToken = Token;
};

let authTokenExpiration = 0;
const setAuthTokenExpiration = (time) => {
  authTokenExpiration = time;
};

let fhirEndpoint = '';
const setFhirEndpoint = (endpoint) => {
  fhirEndpoint = endpoint;
};

const collectionsStored = new Map();
collectionsStored.set('appointments.hc-provider', null);
collectionsStored.set('appointments.hc-agency', null);
collectionsStored.set('clinicalreports.hc-provider', null);
collectionsStored.set('clinicalreports.hc-agency', null);
collectionsStored.set('locations.hc-provider', null);
collectionsStored.set('locations.hc-agency', null);

const connectionStatus = {}; 

async function connectToMongoDB(preferences, collectionName, currentAppUser) {

  console.log("Connecting Step 1 - getting the conexionKey", collectionName);
  const connectionKey = `MongoDBConnection.${collectionName}`;

  console.log("Connecting Step 2 - checking for existing connections", collectionName);

  if (localStorage.getItem(connectionKey) === 'true' && collectionsStored.get(`${collectionName}.${currentAppUser}`) ) {
    console.log("Existing connection found. Returning collection.", collectionName );
    return collectionsStored.get(`${collectionName}.${currentAppUser}`);
  }
  console.log("Connecting Step 3 - waiting for concurent processes", collectionName);
  
  // Check if another connection establishment is already in progress
  if (connectionStatus[`${collectionName}.${currentAppUser}`] && collectionsStored.get(`${collectionName}.${currentAppUser}`)) {
    console.log("Another connection establishment is already in progress. Waiting for completion.", collectionName);
    await waitForConnection(collectionName); // Wait for the existing connection establishment to complete
    console.log("Existing connection established. Returning collection.", collectionName);
    return collectionsStored.get(`${collectionName}.${currentAppUser}`); // Return the existing collection after the connection is established
  }

  try {
    connectionStatus[`${collectionName}.${currentAppUser}`] = true;// Acquire the lock
    console.log("Connecting Step 4", collectionName);
    // Check again if the connection is established by another concurrent process
    if (localStorage.getItem(connectionKey) == 'true' && collectionsStored.get(`${collectionName}.${currentAppUser}`) ) {
      console.log("Existing connection found. Returning collection.", collectionName);
      return collectionsStored.get(collectionName);
    }
    console.log("Connecting Step 5", collectionName);

    const cluster = preferences.dataSets[0].screening.selected[currentAppUser === "hc-provider" ? "HCProviderCluster" : "HCAgencyCluster"];
  
    const fhirAPI = {
      AWS: preferences.dataSets[0].screening.options[1].api,
      Azure: preferences.dataSets[0].screening.options[2].api,
      GCP: preferences.dataSets[0].screening.options[3].api,
    };
  
    const jwtToken = fhirAPI[cluster][`tokenJWT${currentAppUser === 'hc-provider' ? 'HCProvider' : 'HCAgency'}`];

    const realmAppID = fhirAPI[cluster]["AtlasAppID"];
    const realmApp = new Realm.App({ id: realmAppID });

    const credentials = Realm.Credentials.jwt(jwtToken);
    await realmApp.logIn(credentials);
    const mongodb = realmApp.currentUser.mongoClient('mongodb-atlas');

    console.log("mongodb", mongodb);


    console.log("Connecting Step 6", collectionName);
    const collection = mongodb.db('demo_hc_virtual_hospital').collection(collectionName);

    console.log("collection", collection);
  
    console.log("Connecting Step 7", collectionName);
    collectionsStored.set(`${collectionName}.${currentAppUser}`, collection);

    localStorage.setItem(connectionKey, 'true');

    console.log(`A new connection to MongoDB has been established for the "${collectionName}" collection and user ${currentAppUser}`);
    console.log("collection", collection);

    return collection;
  } catch (error) {
    console.error('Error establishing the MongoDB connection:', error);
    throw error;
  } finally {
    connectionStatus[`${collectionName}.${currentAppUser}`] = false; //release the lock
  }
}

async function connectToMongoDBJWT(preferences, currentAppUser){
  if (!authToken || authTokenExpiration >= Date.now() / 1000) { 
    
    const cluster = preferences.dataSets[0].screening.selected[currentAppUser === "hc-provider" ? "HCProviderCluster" : "HCAgencyCluster"];

    const fhirAPI = {
      AWS: preferences.dataSets[0].screening.options[1].api,
      Azure: preferences.dataSets[0].screening.options[2].api,
      GCP: preferences.dataSets[0].screening.options[3].api,
    };

    const fhirEndpoint = fhirAPI[cluster].FHIREndPoint;
    const regionCloud = fhirEndpoint.substring(0, fhirEndpoint.indexOf('.', fhirEndpoint.indexOf('.') + 1));
    setFhirEndpoint(fhirEndpoint);

    const authURL = `${regionCloud}.realm.mongodb.com/api/client/v2.0/app/${fhirAPI[cluster].AtlasAppID}/auth/providers/custom-token/login`;
        
    const jwtToken = fhirAPI[cluster][`tokenJWT${currentAppUser === 'hc-provider' ? 'HCProvider' : 'HCAgency'}`];

    const params = { 
      method: "POST", 
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify( { 'token': `${jwtToken}`})
    };

    let resp = await fetch(authURL, params);

    const Bearer = await resp.json();
    setAuthToken(Bearer);
    const tokenPayload = authToken.access_token.split('.')[1];
    const decodedPayload = JSON.parse(atob(tokenPayload)); 
    const expirationTime = decodedPayload.exp;

    const expiryDate = new Date(expirationTime * 1000);
    setAuthTokenExpiration(expiryDate);}
  
}

function waitForConnection(collectionName) {
  return new Promise(resolve => {
    const checkConnection = setInterval(() => {
      if (!connectionStatus[collectionName]) {
        clearInterval(checkConnection);
        resolve();
      }
    }, 100);
  });
}

const calculateDateOfBirth = (age) => {
  const today = new Date();
  const birthDate = new Date(today.getFullYear() - age, today.getMonth(), today.getDate());
  birthDate.setHours(0, 0, 0, 0);
  return birthDate;
};


const GetFormattedMongoDBMatchFilter = (facets) => {
  const filter = {};
  const locationFilters = facets?.find((filter) => filter.category === 'center');
  const statusFilters = facets?.find((filter) => filter.category === 'status');
  const periodFilters = facets?.filter((filter) => filter.category === 'period');
  const ageFilters = facets?.filter((filter) => filter.category === 'age');

  if (locationFilters) {
   filter['location.display'] = { $in: locationFilters.value };
  }

  if (statusFilters) {
    filter.status = { $in: statusFilters.value };
  }

  if (ageFilters && ageFilters.length > 0) {
    const ageConditions = ageFilters.flatMap((ageFilter) => {
      return ageFilter.value.map((ageFilterValue) => {

        switch (ageFilterValue) {
          case '49':
            return {
              'subject.birthDate': {
                $gte: new Date(calculateDateOfBirth(50)),
              },
            };
          case '50-59':
            return {
              'subject.birthDate': {
                $gte: new Date(calculateDateOfBirth(60)),
                $lt: new Date(calculateDateOfBirth(50)),
              },
            };
          case '60-69':
            return {
              'subject.birthDate': {
                $gte: new Date(calculateDateOfBirth(70)),
                $lt: new Date(calculateDateOfBirth(60)),
              },
            };
          case '70':
            return {
              'subject.birthDate': {
                $lte: new Date(calculateDateOfBirth(70)),
              },
            };
          default:
            console.error('Unsupported age filter', ageFilterValue);
            return null;
        }
      });
    });

    const validAgeConditions = ageConditions.filter((condition) => condition !== null);
    if (validAgeConditions.length > 0) {
      filter.$or = validAgeConditions;
    }
  }

  if (periodFilters?.length > 0) {
    const today = new Date();
    const startOfDay = new Date(today);
    startOfDay.setHours(0, 0, 0, 0);
    const endOfDay = new Date(today);
    endOfDay.setHours(23, 59, 59, 999);

    const selectedOptions = periodFilters.flatMap((periodFilter) => periodFilter.value);

    if (selectedOptions.includes('month')) {
      const monthStart = new Date(today.getFullYear(), today.getMonth(), 1);
      const monthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      monthEnd.setHours(23, 59, 59, 999);
      filter.start = { $gte: monthStart, $lte: monthEnd };
    } else if (selectedOptions.includes('week')) {
      const weekStart = new Date(today);
      weekStart.setDate(today.getDate() - today.getDay() + 1);
      weekStart.setHours(0, 0, 0, 0);
      const weekEnd = new Date(today);
      weekEnd.setDate(today.getDate() - today.getDay() + 7);
      weekEnd.setHours(23, 59, 59, 999);
      filter.start = { $gte: weekStart, $lte: weekEnd };
    } else if (selectedOptions.includes('today')) {
      filter.start = { $gte: startOfDay, $lte: endOfDay };
    } else {
      console.error('Unsupported period filter');
    }
  }

  return filter;
};

export async function getLocationId(preferences, currentAppUser) {
  try {
    const locations = await connectToMongoDB(preferences, "locations", currentAppUser);
    const locationDocument = await locations.aggregate([{ $sort: { _id: 1 } }, { $limit: 1 }]);
    if (locationDocument && locationDocument.length > 0) {
      return locationDocument[0].name;
    } else {
      return null; 
    }
  } catch (err) {
    console.log("Error occurred while fetching location document:", err.message);
    return null; 
  }
}

const getFormattedFHIRQueryParams = (facets) => {
  const queryParams = [];

  if (facets && Array.isArray(facets) && facets.length > 0) {
    const locationFilters = facets?.find((filter) => filter.category === 'center');
    const statusFilters = facets.find((filter) => filter.category === 'status');
    const periodFilters = facets.filter((filter) => filter.category === 'period');
    const ageFilters = facets.filter((filter) => filter.category === 'age'); 

    if (locationFilters) {
      const encodedString = locationFilters.value.map(value => encodeURIComponent(value)).join(',');
      const locationFilterParam = `location=${encodedString}`;
      queryParams.push(locationFilterParam);
    }

    if (ageFilters && ageFilters.length > 0) {
      const ageConditions = ageFilters.flatMap((ageFilter) => {
        return ageFilter.value.map((ageFilterValue) => {
          switch (ageFilterValue) {
            case '49':
              return `birthdate=ge${calculateDateOfBirth(50).toISOString().split('T')[0]}`;
            case '50-59':
              return `birthdate=ge${calculateDateOfBirth(60).toISOString().split('T')[0]},lt${calculateDateOfBirth(50).toISOString().split('T')[0]}`;
            case '60-69':
              return `birthdate=ge${calculateDateOfBirth(70).toISOString().split('T')[0]},lt${calculateDateOfBirth(60).toISOString().split('T')[0]}`;
            case '70':
              return `birthdate=le${calculateDateOfBirth(70).toISOString().split('T')[0]}`;
            default:
              console.error('Unsupported age filter', ageFilterValue);
              return null;
          } // filtering by age we can't select several filter for age, they will get overwritten in the API
        });
      });

      const validAgeConditions = ageConditions.filter((condition) => condition !== null);
      if (validAgeConditions.length > 0) {
        queryParams.push(...validAgeConditions);
      }
    }

    if (statusFilters && statusFilters.value) {
      const statusParam = `status=${statusFilters.value.join(',')}`;
      queryParams.push(statusParam);
    }

    if (periodFilters && periodFilters.length > 0) {
      periodFilters.forEach((periodFilter) => {
        let periodParam = '';

        switch (periodFilter.value[0]) {
          case 'today':
            const today = new Date().toISOString().split('T')[0];
            const tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            periodParam = `date=ge${today},le${tomorrow.toISOString().split('T')[0]}`;
            break;
          case 'week':
            const weekStart = new Date();
            weekStart.setDate(weekStart.getDate() - weekStart.getDay() + 1);
            const weekEnd = new Date();
            weekEnd.setDate(weekEnd.getDate() - weekEnd.getDay() + 8);
            periodParam = `date=ge${weekStart.toISOString().split('T')[0]},le${weekEnd.toISOString().split('T')[0]}`;
            break;
          case 'month':
            const monthStart = new Date();
            monthStart.setDate(1);
            const monthEnd = new Date(monthStart.getFullYear(), monthStart.getMonth() + 1, 2);
            periodParam = `date=ge${monthStart.toISOString().split('T')[0]},le${monthEnd.toISOString().split('T')[0]}`;
            break;
          default:
            console.error('Unsupported period filter');
        }

        queryParams.push(periodParam);
      });
    }
  }
  return queryParams.join('&');
};

const postProcessFHIRResult = (fhirResult) => {
  return fhirResult.entry.map((entry) => {
    const resource = entry.resource;

    let created = resource.created;
    if (typeof created === 'object' && created.$date) {
      created = new Date(created.$date.$numberLong);
    }

    let start = resource.start;
    if (typeof start === 'object' && start.$date) {
      start = new Date(start.$date.$numberLong);
    }
    
    const subject = resource.subject || {};

    const participant1 = resource.participant[0] || {};

    const actor1Display = participant1.actor
      ? Array.isArray(participant1.actor.display)
        ? {
            given: participant1.actor.display[0]?.given || [],
            family: participant1.actor.display[0]?.family || '',
          }
        : {
            given: [participant1.actor.display?.given || ''],
            family: participant1.actor.display?.family || '',
          }
      : { given: [], family: '' };

    return {
      _id: resource._id.$oid,
      resourceType: resource.resourceType,
      status: resource.status,
      created: created,
      start: start,
      description: resource.description,
      serviceType: resource.serviceType,
      subject: subject,
      participant: [
        {
          actor: {
            reference: participant1.actor ? participant1.actor.reference.$oid : '',
            display: [{
              given: actor1Display.given,
              family: actor1Display.family,
            }],
            birthDate: participant1.actor && participant1.actor.birthDate
              ? new Date(participant1.actor.birthDate).toISOString()
              : null
          },
          required: participant1.required,
          status: participant1.status
        },
      ],
      location: {
        reference: resource.location.reference.$oid,
        display: resource.location.display
      }
    };
  });
};

export const APILoadInitialAppointments = async (selectedFilters, sortField, sortOrder, preferences, currentAppUser, currentCenter) => {

  if (preferences.dataSets[0].screening.selected.HCProviderApi == 'MongoDB') {
    try {
      const collection = await connectToMongoDB(preferences, "appointments", currentAppUser);

      console.log ("collection from Load Appointment",collection );
      const sort = {};
      sort[sortField] = sortOrder === 'asc' ? 1 : -1;

      const pipeline = [];

      if (selectedFilters.searchQuery) {
        const searchStage = JSON.parse(preferences.screening.atlasSearchStage);
        searchStage.text.query = selectedFilters.searchQuery;
        pipeline.push({
          $search: searchStage
        });
      }

      console.log("currentCenter", currentCenter);

      if (selectedFilters.facets && selectedFilters.facets.length > 0) {
        const matchFilter = GetFormattedMongoDBMatchFilter(selectedFilters.facets);

        pipeline.push({ $match: matchFilter });
      }

      pipeline.push(
        { $sort: sort },
        { $limit: preferences.screening.loadInitialAppointments }
      );

      const transactionID = logActivity.start("Load Appointments", "User", "Cluster", "API", "User", "Cluster", "API");
      logActivity.addEntry(transactionID, "code-request", "Query to Atlas - collection.aggregate()", pipeline);
      const initialAppointments = await collection.aggregate(pipeline);
      logActivity.addEntry(transactionID, "code-response", "Response", initialAppointments);
      logActivity.end(transactionID);

      return { initialAppointments };
    } catch (error) {
      console.error('Error loading initial appointments:', error);
      throw error;
    }
  } else {
    // Handle FHIR case
    try {
      
      const queryParams = getFormattedFHIRQueryParams(selectedFilters.facets);

      await connectToMongoDBJWT(preferences, currentAppUser);

      const params = { 
        method: "GET", 
        headers: {
        'Authorization': `Bearer ${authToken.access_token}`,
        'Content-Type': 'application/json'}
      };

      const sortFieldMap = {
        status: 'status',
        name: 'name', 
        age: 'birthdate', 
        start: 'date', 
        institution: 'location', 
      };
      
      let _sort = '';
      if (sortField && sortFieldMap.hasOwnProperty(sortField)) {
        const mappedSortField = sortFieldMap[sortField];
        _sort = sortOrder === 'desc' ? `&_sort=-${mappedSortField}` : `&_sort=${mappedSortField}`;
      }
      const _count = preferences.screening.loadMoreAppointments;

      const endpointURL = `${fhirEndpoint}Appointment?${queryParams}&_count=${_count}${_sort}`;

      const transactionID = logActivity.start("Loading Initial Appointments - FHIR", "User", "Cluster", "API");
      logActivity.addEntry(transactionID, "code-request", "Query to FHIR endpoint", endpointURL);

      const  response = await fetch(endpointURL, params);
      const fhirResult = await response.json();
      logActivity.addEntry(transactionID, "code-response", "FHIRResult", fhirResult);
      const initialAppointments = postProcessFHIRResult(fhirResult, params);
      logActivity.addEntry(transactionID, "code-response", "PostProcess FHIRResult", initialAppointments);
      logActivity.end(transactionID);
      const links = fhirResult.link;

      const nextLink = links.find((link) => link.relation === 'next') || null;
      const prevLink = links.find((link) => link.relation === 'prev') || null;
      const totalPages = fhirResult.total/initialAppointments.length

      return { initialAppointments, nextLink, prevLink, totalPages };
    } catch (error) {
      console.error('Error loading initial appointments:', error);
      throw error;
    }
  }
};

export const APILoadMoreAppointments = async (appointments, selectedFilters, sortField, sortOrder, preferences, currentAppUser, currentCenter) => {
  try {
    const collection = await connectToMongoDB(preferences, "appointments", currentAppUser);

    const sort = {};
    sort[sortField] = sortOrder === 'asc' ? 1 : -1;

    const pipeline = [];


    if (selectedFilters.searchQuery) {
      const searchStage = JSON.parse(preferences.screening.atlasSearchStage);
      searchStage.text.query = selectedFilters.searchQuery;
      pipeline.push({
        $search: searchStage
      });
    }

    if (selectedFilters.facets && selectedFilters.facets.length > 0) {
      const matchFilter = GetFormattedMongoDBMatchFilter(selectedFilters.facets);
      pipeline.push({ $match: matchFilter });
    }

    pipeline.push(
      { $match: { _id: { $nin: appointments.map(appointment => appointment._id) } } }, // Exclude existing appointments
      { $sort: sort },
      { $limit: preferences.screening.loadMoreAppointments }
    );

    console.log(pipeline)
    console.log(collection)


    const transactionID = logActivity.start("Loading More Appointments", "User", "Cluster", "API");
    logActivity.addEntry(transactionID, "code-request", "Query to Atlas - collection.aggregate()", pipeline);
    const newAppointments = await collection.aggregate(pipeline);
    logActivity.addEntry(transactionID, "code-response", "Response", newAppointments);
    logActivity.end(transactionID);

    return newAppointments;
    
  } catch (error) {
    console.error('Error loading new appointments:', error);
    throw error;
  }
};

export const APILoadPage = async (URL, preferences, currentAppUser) => {
  try {

    await connectToMongoDBJWT(preferences, currentAppUser);

      const params = { 
        method: "GET", 
        headers: {
        'Authorization': `Bearer ${authToken.access_token}`,
        'Content-Type': 'application/json'}
      };

    const response = await fetch(URL, params);
    const fhirResult = await response.json();
    const nextAppointments = postProcessFHIRResult(fhirResult);

    const links = fhirResult.link;
    const nextLink = links.find((link) => link.relation === 'next')|| null;
    const prevLink = links.find((link) => link.relation === 'previous')|| null;

    return { nextAppointments, nextLink, prevLink};
  } catch (error) {
    console.error('Error loading new appointments:', error);
    throw error;
  }
};

export const APICountAppointments = async (selectedFilters, applyFilters, preferences, currentAppUser, currentCenter) => {
  try {
    const collection = await connectToMongoDB(preferences, "appointments", currentAppUser);

    if (preferences.dataSets[0].screening.selected.HCProviderApi == 'MongoDB') {

    const pipeline = [];

    if (applyFilters) {
      if (selectedFilters.searchQuery) {
        const searchStage = JSON.parse(preferences.screening.atlasSearchStage);
        searchStage.text.query = selectedFilters.searchQuery;
        pipeline.push({
          $search: searchStage
        });
      }

      if (selectedFilters.facets && selectedFilters.facets.length > 0) {
        if (preferences.dataSets[0].screening.selected.HCProviderApi == 'MongoDB') {
          const matchFilter = GetFormattedMongoDBMatchFilter(selectedFilters.facets);
          pipeline.push({ $match: matchFilter });
        } 
      }
    }

      pipeline.push({ $count: "total" });

      const transactionID = logActivity.start("Count Appointments", "User", "Cluster", "API");
      logActivity.addEntry(transactionID, "code-request", "Query to Atlas - collection.aggregate()", pipeline);
      const count = await collection.aggregate(pipeline);
      logActivity.addEntry(transactionID, "code-response", "Response", count);
      logActivity.end(transactionID);


      if (count.length > 0) {
        return count[0].total;
      } else {
        return 0;
      }
    } else {
      //FHIR
      await connectToMongoDBJWT(preferences, currentAppUser);

      const params = { 
        method: "GET", 
        headers: {
        'Authorization': `Bearer ${authToken.access_token}`,
        'Content-Type': 'application/json'}
      };

      let endpointURL

      if (applyFilters) {
        const _count = preferences.screening.loadMoreAppointments;
        const queryParams = getFormattedFHIRQueryParams(selectedFilters.facets);
        endpointURL = `${fhirEndpoint}Appointment?${queryParams}&_count=${_count}`;
      } else {
        endpointURL = `${fhirEndpoint}Appointment?_count=0`
      }
      
      const  response = await fetch(endpointURL, params);
      const fhirResult = await response.json();
      
      return fhirResult.total;
    }
  } catch (error) {
    console.error('Error counting appointments:', error);
    throw error;
  }
};

export const APIGetAppointmentFacets = async (path, preferences, currentAppUser) => {
  try {
    const collection = await connectToMongoDB(preferences, "appointments", currentAppUser);

    const pipeline = [];

    pipeline.push({
      $searchMeta: {
        index: "hc-appointments",
        facet: {
          facets: {
            stringFacet: {
              type: "string",
              path: path
            }
          }
        }
      }
    });
    /*

    logActivity("title2", "Count Appointments - MongoDB MQL", null, null, setActivityLog);
    const requestStartTime = logActivity("code-request", "Query to Atlas", pipeline, null, setActivityLog);
    const facets = await collection.aggregate(pipeline);
    logActivity("code-request", "Response", facets, requestStartTime.timestamp, setActivityLog);
    console.log("Facets", facets);
*/


  } catch (error) {
    console.error('Error retrieving facets:', error);
    throw error;
  }
};


export const APIGetClinicalReport = async (appointmentId, preferences, currentAppUser) => {

  try {

    const collection = await connectToMongoDB(preferences, "clinicalreports", currentAppUser);

    const appointmentIdStr = appointmentId.toString();

    const transactionID = logActivity.start("Get Clinical Report", "User", "Cluster", "API");
    logActivity.addEntry(transactionID, "code-request", "Query to Atlas - collection.aggregate()", appointmentIdStr);
    const report = await collection.findOne({ appointmentID: appointmentIdStr });
    logActivity.addEntry(transactionID, "code-response", "Response", report);
    logActivity.end(transactionID);

    return report;
  } catch (error) {
    console.error('Error fetching clinical report:', error);
    throw error;
  }
};


export const APIVectorSearchReports = async (vectorizedPrompt, preferences) => {

  try {
    const collection = await connectToMongoDB(preferences, "clinicalreports");

    const pipeline = [];

    const searchStageString = preferences.advancedSearch.vectorSearchStage;
    const searchStage = JSON.parse(searchStageString);
    searchStage.knnBeta.vector = vectorizedPrompt;
    pipeline.push({
    $search: searchStage
     });

    pipeline.push({
      $project: {
        vectorEmbedding: 0,
        _id: 0,
        score: {
          $meta: 'searchScore'
        }
      }
    });

    const transactionID = logActivity.start("Loading vector search results", "User", "Cluster", "API");
    logActivity.addEntry(transactionID, "code-request", "Query to Atlas - collection.aggregate()", pipeline);
    const matchingReports = await collection.aggregate(pipeline);
    logActivity.addEntry(transactionID, "code-response", "Response", matchingReports);
    logActivity.end(transactionID);


    return matchingReports;
  } catch (error) {
    console.error('Error executing vector search query to Atlas:', error);
    throw error;
  }
};


export const APIProcessMammography = async (params, preferences) => {
  try {
    const transactionID = logActivity.start("Call Python script to start the mammography process", "User", "Cluster", "API");
    
    const url = `${preferences.screening.DicomImageEndPoint}?${params}`;

    logActivity.addEntry(transactionID, "code-request", "Fetch url", url);

    const response = await fetch(url);
    logActivity.addEntry(transactionID, "code-response", "Response", response);

    if (!response.ok) {
      throw new Error("Error executing the Dicom extract and upload");
    }

    // Handle the response if needed
    return response;
  } catch (error) {
    const errorMessage =
      "The endpoint to execute the Dicom extract and upload is not running. Please review the documentation.";
    throw new Error(errorMessage);
  }
};


export const APISaveClinicalReport = async (document, clinicalNotes, conclusions, conclusionsSNOMED, vectorizedReport, preferences, currentAppUser) => {
  try {
    const collection = await connectToMongoDB(preferences, "clinicalreports", currentAppUser);

    const transactionID = logActivity.start('Saving clinical report', "User", "Cluster", "API");

    // Modify the series subdocument to an array of imageMetadata objects
    const series = [];
    const lateralityViewPositions = Object.keys(document.series);
    for (const laterality of lateralityViewPositions) {
      for (const viewPosition of Object.keys(document.series[laterality])) {
        const subdocument = document.series[laterality][viewPosition];
        const imageMetadata = subdocument.imageMetadata;
        series.push({ imageMetadata: imageMetadata });
      }
    }

    // Create the clinical report with the updated series array
    const clinicalReport = {
      ...document,
      clinicalNotes: clinicalNotes,
      conclusions: conclusions,
      conclusionsSNOMED: conclusionsSNOMED,
      vectorEmbedding: vectorizedReport,
      series: series,
    };

    logActivity.addEntry(transactionID, 'code-request', 'Request to Atlas - collection.insertOne', clinicalReport);
    await collection.insertOne(clinicalReport);
    logActivity.addEntry(transactionID, 'code-response', 'Response - Inserted ID', 1);
    logActivity.end(transactionID);

    return true;
  } catch (error) {
    console.error('Error saving clinical report:', error);
    throw error;
  }
};


export const APIUpdateAppointmentStatus = async (appointmentId, status, preferences, currentAppUser) => {
  try {
    const collection = await connectToMongoDB(preferences, "clinicalreports");

    await collection.updateOne(
      { _id: appointmentId },
      { $set: { status: status } }
    );

  } catch (error) {
    console.error('Error updating appointment:', error);
  }
};

export const APIGenerateAndGetVector = async (query, preferences, currentAppUser) => {
  try {
    const model = preferences.advancedSearch.LLMModel;
    const endpoint = preferences.advancedSearch.LLMAPI;
    const transactionID = logActivity.start('Calling python script to vectorize user prompt', "User", "Cluster", "API");
    logActivity.addEntry(transactionID, 'html', 'Data flow', 'vectorSearchArch/index.html');
    logActivity.addEntry(transactionID, 'code-request', `Request to endpoint: ${endpoint}?query=${query}&model=${model}`, query);
    const response = await axios.get(`${endpoint}?query=${query}&model=${model}`);
    const vectorizedPrompt = response.data;
    logActivity.addEntry(transactionID, 'code-response', 'Response - Vectorized Prompt', vectorizedPrompt);
    logActivity.end(transactionID);

    return vectorizedPrompt;
  } catch (error) {
    console.error('Error generating and obtaining vector:', error);
    throw error;
  }
};