// ReportForm.js
import React, { useState, useContext } from "react"
import { AppContext } from '../../context/AppContext';
import './ReportForm.css';
import SpeechToText from "../SpeechToText/SpeechToText.js";

function ReportForm({ lastDocument, age, clinicalNotes, setClinicalNotes, conclusions, setConclusions, conclusionsSNOMED, setConclusionsSNOMED}) {
    const [isGeneratingClinicalNotes, setIsGeneratingClinicalNotes] = useState(false);
    const [isGeneratingConclusions, setIsGeneratingConclusions] = useState(false);
    const [isGeneratingSNOMED, setIsGeneratingSNOMED] = useState(false);
    const {preferences, currentAppUser } = useContext(AppContext);
    const [finalText, setFinalText] = useState(""); 

    
    const handleSpeechResult = (result) => {
            setFinalText(result); 
        };

    //LLM API conf
    const apiUrl = preferences.screening.generateTextService.apiURL;
    const headers = {
        "Content-Type": "application/json",
        "Authorization": preferences.screening.generateTextService.authorization
    };
    const model = preferences.screening.generateTextService.model;

    //console.log("apiUrl", apiUrl);
    //console.log("bearer", preferences.screening.generateTextService.authorization);
    //console.log("model called", model);

    async function fetchData(url, headers, requestBody) {
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(requestBody)
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.log("Error:", error);
            throw error;
        }
    }

    async function generateClinicalNotes() {
        try {
            setIsGeneratingClinicalNotes(true);
            const LCCBiRads = lastDocument?.series?.L?.CC?.imageMetadata?.biRads;
            const LMLOBiRads = lastDocument?.series?.L?.MLO?.imageMetadata?.biRads;
            const RCCBiRads = lastDocument?.series?.R?.CC?.imageMetadata?.biRads;
            const RMLOBiRads = lastDocument?.series?.R?.MLO?.imageMetadata?.biRads;

            const clinicalNotesRequestBody = {
                model: model,
                messages: [
                    { role: "system", content: "You are a random radiologist reviewing a mammography evaluation. Use abbreviations or even some fuzzy words, just like doctors write." },
                    { role: "user", content: `Patient's age: ${age}` },
                    { role: "user", content: `BI-RADS scores: L-CC: ${LCCBiRads}, L-MLO: ${LMLOBiRads}  R-CC: ${RCCBiRads}  R-MLO: ${RMLOBiRads}` },
                    { role: "assistant", content: "Generate random clinical notes for these given observations suggesting new tests. Show patient's age in normal format not coded one.Mention also a random clinical and family history for breast cancer. These should include only the findings but not recommendations or diagnostic" },
                ],
                max_tokens: 256
            };

            const clinicalNotesData = await fetchData(apiUrl, headers, clinicalNotesRequestBody);
            const clinicalNotes = clinicalNotesData.choices[0].message.content;
            setIsGeneratingClinicalNotes(false);
            setClinicalNotes(clinicalNotes);

            setIsGeneratingConclusions(true);
            const diagnosisConclusionsRequestBody = {
                model: model,
                messages: [
                    { role: "system", content: "You are a random radiologist reviewing a mammography evaluation. Use abbreviations or even some fuzzy words, just like doctors write." },
                    { role: "assistant", content: `I'm providing a random text for clinical notes for breast cancer screening process. According to these notes, and without listing the BI-RADS for every image, provide a report close to 256 words, mostly with diagnostic, next recommended steps and indicate if necessary if there is an urgency. These are the clinical notes that you have to use: ${clinicalNotes}` },
                ],
                max_tokens: 256
            };

            const diagnosisConclusionsData = await fetchData(apiUrl, headers, diagnosisConclusionsRequestBody);
            const diagnosisConclusions = diagnosisConclusionsData.choices[0].message.content;
            setIsGeneratingConclusions(false);
            setConclusions(diagnosisConclusions);

            setIsGeneratingSNOMED(true);
            const diagnosisCodingRequestBody = {
                model: model,
                messages: [
                    { role: "system", content: "You are a system able to simulate condifying with SNOMED for education purposes." },
                    { role: "assistant", content: `Provide the list of simulated SNOMED codes for this diagnostic reports for a software demo purpose ${diagnosisConclusions}` },
                ],
                max_tokens: 400
            };

            const diagnosisCodingData = await fetchData(apiUrl, headers, diagnosisCodingRequestBody);
            const diagnosisConclusionsCoded = diagnosisCodingData.choices[0].message.content;
            setIsGeneratingSNOMED(false);
            setConclusionsSNOMED(diagnosisConclusionsCoded);

        } catch (error) {
            console.log("Error (generateClinicalNotes):", error);
        }
    }

  
    return (
        <div id="reportForm" className="reportForm my-4">
            
            <label htmlFor="clinicalNotes">Clinical Notes</label>
            <textarea id="clinicalNotes" rows="4" cols="50"
                value={isGeneratingClinicalNotes ? "Generating automatic text through ChatGPT..." : clinicalNotes}
                readOnly={isGeneratingClinicalNotes ? true : false}
                onChange={(e) => setClinicalNotes(e.target.value)}
                className="mb-0"
            ></textarea>
    
            <SpeechToText onSpeechResult={(result) => setClinicalNotes(result)} />
    
            <label htmlFor="conclusions">Conclusions</label>
            <textarea id="conclusions" rows="4" cols="50"
                value={isGeneratingConclusions ? "Generating automatic text through ChatGPT..." : conclusions}
                readOnly={isGeneratingConclusions ? true : false}
                onChange={(e) => setConclusions(e.target.value)}
                className="mb-0"
            ></textarea>
            <SpeechToText onSpeechResult={(result) => setConclusions(result)} />
    
            <label htmlFor="conclusionsSNOMED">SNOMED</label>
            <textarea id="conclusionsSNOMED" rows="4" cols="50"
                value={isGeneratingSNOMED ? "Generating automatic text through ChatGPT..." : conclusionsSNOMED}
                readOnly
                onChange={(e) => setConclusionsSNOMED(e.target.value)}
            ></textarea>
    
            <button variant="secondary" onClick={generateClinicalNotes} className="autoGenerateCFButton px-4 py-1 border border-black text-gray-400 rounded-full hover:text-white hover:bg-gray-400 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2">
                Auto-generate Clinical Notes
            </button>
        </div>
    )
    }
    export default ReportForm;

