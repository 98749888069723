// App.js
import './index.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NavBar from './components/NavBar/NavBar';
import Overview from './pages/ProjectOverview/Overview';
import ScreeningList from './pages/ScreeningList/ScreeningList';
import OverallStatus from './pages/OverallStatus/OverallStatus';
import Preferences from './pages/Preferences/Preferences';
import AdvancedSearch from './pages/AdvancedSearch/AdvancedSearch';
import { AppContext, AppProvider } from './context/AppContext';
import UserNewSession from './components/UserNewSession/UserNewSession';
import Footer from './components/Footer/footer';

const App = () => {
  const [isReturningUser, setIsReturningUser] = useState(false);

  useEffect(() => {
    // Check if user is a returning customer based on localStorage
    const email = localStorage.getItem('email');
    const isReturning = !!email;
    setIsReturningUser(isReturning);
  }, []);

  return (
    <BrowserRouter>
      <AppProvider>
        <UserNewSession />
        <NavBar />
        <Routes>
          <Route path="/" element={<Overview />} />
          <Route path="/screening" element={<ScreeningList />} />
          <Route path="/overall" element={<OverallStatus />} />
          <Route path="/advancedSearch" element={<AdvancedSearch />} />
          <Route path="/preferences" element={<Preferences />} />
        </Routes>
        <Footer />
      </AppProvider>
    </BrowserRouter>
  );
};

export default App;

