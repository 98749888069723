import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../context/AppContext';
import loadingGif from './loading.gif'; // Use require to import the GIF
import './DicomViewer.css';

const DicomViewer = ({ awsS3URL, closeModal }) => {
  const { preferences } = useContext(AppContext);
  const dicomViewerURL = preferences.screening.DicomViewerEndPoint;
  const encodedAwsS3URL = encodeURIComponent(awsS3URL);
  const iframeSrc = `${dicomViewerURL}?url=${encodedAwsS3URL}`;

  const [brightness, setBrightness] = useState(100);
  const [contrast, setContrast] = useState(100);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [localImage, setLocalImage] = useState(null); // Store the local copy of the image
  const [iframeDocument, setIframeDocument] = useState(null);

  // Function to fetch the image from the API and create a local copy
  const fetchAndCopyImage = async () => {
    try {
      const response = await fetch(iframeSrc); // Fetch the image from the API
      if (!response.ok) {
        throw new Error('Failed to fetch image');
      }
      const blob = await response.blob();
      const localImageUrl = URL.createObjectURL(blob); // Create a local copy
      setLocalImage(localImageUrl);
    } catch (error) {
      console.error('Error fetching image:', error);
    }
  };

  useEffect(() => {
    // Fetch and copy the image when the component mounts
    fetchAndCopyImage();
  }, [awsS3URL]);

  return (
    <div className="modal-container">
      <div className="modal-header">
        <button className="close-button" onClick={closeModal}>
          &#x2715; Close
        </button>
      </div>
      <div className="image-container">
        {localImage ? (
          <img
            src={localImage}
            alt="Image"
            style={{
              filter: `brightness(${brightness}%) contrast(${contrast}%)`, // Apply brightness and contrast filters
            }}
          />
        ) : (
          <div className="loading-container">
            <img src={loadingGif} alt="Loading..." className="w-1/6"/>
          </div>
        )}
      </div>
      <div className="controls">
        <label htmlFor="brightness">Brightness:</label>
        <input
          type="range"
          id="brightness"
          min="0"
          max="200"
          value={brightness}
          onChange={(e) => setBrightness(e.target.value)}
        />
        <label htmlFor="contrast">Contrast:</label>
        <input
          type="range"
          id="contrast"
          min="0"
          max="200"
          value={contrast}
          onChange={(e) => setContrast(e.target.value)}
        />
      </div>
    </div>
  );
};

export default DicomViewer;
