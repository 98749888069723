import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from '../../context/AppContext';
import * as Realm from 'realm-web';
import './Preferences.css';
import AlertReload from '../../components/Alert/AlertReload';
import { timezones } from '../../components/UserNewSession/locales.js';
//import { BiRefresh } from 'react-icons/bi';

import MongoDBLogo from './img/MongoDBLogo.png';
import HL7FHIRLogo from './img/HL7FHIRLogo.png';
import MongoDBEALogo from './img/MongoDBLogo.png';
import AWSLogo from './img/AWS.png';
import AzureLogo from './img/Azure.png';
import GCPLogo from './img/GCP.png';
import refresh from './img/refresh.png';
import cluster_1 from './img/cluster1.png';
import cluster_2 from './img/cluster2.png';

const Preferences = () => {
  const { preferences, setPreferences } = useContext(AppContext);
  const [updatedPreferences, setUpdatedPreferences] = useState(preferences);
  const [isSaving, setIsSaving] = useState(false);
  const { translations } = useContext(AppContext);
  const [showAlertReload, setShowAlertReload] = useState(false);
  const [isTranslationsUpdated, setIsTranslationsUpdated] = useState(false);

  useEffect(() => {
    setIsTranslationsUpdated(true);
  }, [translations]);

  const handleReloadPage = () => {
    window.location.reload();
  };

  const REALM_APP_ID = process.env.REACT_APP_REALM_APP_ID;

  const realmApp = new Realm.App({ id: REALM_APP_ID });

  const handleBlur = async (reload) => {
    try {
      setIsSaving(true);
      const mongodb = realmApp.currentUser.mongoClient('mongodb-atlas');
      const collection = mongodb.db('demo_hc_virtual_hospital_core').collection('user-preferences');
      const email = localStorage.getItem('email');
      await collection.updateOne({ _id: email }, { $set: updatedPreferences });
      setPreferences(updatedPreferences);
      //When preferences change, then we force reconnecting
      let connectionKey = 'MongoDBConnection.appointments';
      localStorage.setItem(connectionKey, null);
      connectionKey = 'MongoDBConnection.clinicalreports';
      localStorage.setItem(connectionKey, null);
      connectionKey = 'MongoDBConnection.locations';
      localStorage.setItem(connectionKey, null);
    } catch (error) {
      console.error('Error saving preferences:', error);
    } finally {
      setIsSaving(false);
      if (reload) {
        setShowAlertReload(true);
      }
    }
  };

  const handleAlertClose = () => {
    setShowAlertReload(false);
  };

  const handlePromptChange = (promptIndex, messageIndex, field, value) => {
    setUpdatedPreferences((prevPreferences) => {
      const prompts = [...prevPreferences.screening.generateTextService.prompts];
      prompts[promptIndex].messages[messageIndex][field] = value;
      return {
        ...prevPreferences,
        screening: {
          ...prevPreferences.screening,
          generateTextService: {
            ...prevPreferences.screening.generateTextService,
            prompts,
          },
        },
      };
    });
  };

  const handleAddPrompt = (promptIndex) => {
    setUpdatedPreferences((prevPreferences) => {
      const prompts = [...prevPreferences.screening.generateTextService.prompts];
      if (!Array.isArray(prompts[promptIndex].messages)) {
        prompts[promptIndex].messages = []; // Initialize as an empty array if messages is not an array
      }
      prompts[promptIndex].messages.push({ role: 'system', content: '' });
      return {
        ...prevPreferences,
        screening: {
          ...prevPreferences.screening,
          generateTextService: {
            ...prevPreferences.screening.generateTextService,
            prompts,
          },
        },
      };
    });
  };

  const handleRemovePrompt = (promptIndex, messageIndex) => {
    setUpdatedPreferences((prevPreferences) => {
      const prompts = [...prevPreferences.screening.generateTextService.prompts];
      if (Array.isArray(prompts[promptIndex].messages)) {
        prompts[promptIndex].messages.splice(messageIndex, 1);
      }
      return {
        ...prevPreferences,
        screening: {
          ...prevPreferences.screening,
          generateTextService: {
            ...prevPreferences.screening.generateTextService,
            prompts,
          },
        },
      };
    });
  };

  const handleRemovePromptExample = (index) => {
    setUpdatedPreferences((prevPreferences) => {
      const updatedPrompts = [...prevPreferences.advancedSearch.promptExamples];
      updatedPrompts.splice(index, 1);
      return {
        ...prevPreferences,
        advancedSearch: {
          ...prevPreferences.advancedSearch,
          promptExamples: updatedPrompts,
        },
      };
    });
  };

  const handleAddPromptExample = () => {
    setUpdatedPreferences((prevPreferences) => ({
      ...prevPreferences,
      advancedSearch: {
        ...prevPreferences.advancedSearch,
        promptExamples: [...prevPreferences.advancedSearch.promptExamples, ''],
      },
    }));
  };


  const handleRestoreDefaults = async () => {
    try {
      const mongodb = realmApp.currentUser.mongoClient('mongodb-atlas');
      const collectionPreferences = mongodb.db('demo_hc_virtual_hospital_core').collection('user-preferences');
      const email = localStorage.getItem('email');

      // Remove the document for the current email
      await collectionPreferences.deleteOne({ _id: email });

      setShowAlertReload(true);
      ;
    } catch (error) {
      console.error('Error restoring defaults:', error);
    }
  };


    return (
      <React.Fragment>
        {showAlertReload && isTranslationsUpdated && (
          <AlertReload title="Translations Updated" message="Please reload the page to apply the changes." onReload={handleReloadPage} onClose={handleAlertClose} />
        )}
        <div className="preferences-page" style={{ marginBottom: '120px' }}>
          <div className='ml-7 mb-10'>
            <h1>Preferences</h1>
            <p>Explore and customize your preferences by selecting desired data APIs, choose preferred  cluster locations, and personalize general settings.</p>
          </div>

        <button
          className="restore-defaults-button flex px-3 py-1 border border-black text-gray-400 rounded-full hover:text-white hover:bg-gray-400 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2"
          onClick={handleRestoreDefaults}
          disabled={isSaving}
          style={{ position: 'absolute', top: '20px', right: '80px' }}
        >
          <img
            className='w-5 pt-1 pr-1'
            src={refresh}
            alt=""
          />
          Re-establish defaults
        </button>

        <hr className='ml-7 mr-20' />

        <div className="section">
          <h2 className="left-align">Data API</h2>
          <p>Tailor your data source to best suit your requirements.</p>
          <table className="api-type-table">
            <thead>
              <tr>
                <th></th>
                <th>
                  <img src={MongoDBLogo} alt="MongoDB Logo" width="120" />
                </th>
                <th>
                  <img src={HL7FHIRLogo} alt="HL7 FHIR Logo" width="120" />
                </th>
              </tr>
            </thead>
            <tbody className="px-5 py-5 bg-white rounded-lg border-b border-gray-200 bg-white shadow-md text-base">
              <tr>
                <td className='pl-4'>Healthcare Provider</td>
                <td className='pl-14'>
                  <input
                    type="radio"
                    name="hc-provider-api-type"
                    value="MongoDB"
                    checked={updatedPreferences.dataSets[0].screening.selected.HCProviderApi === 'MongoDB'}
                    onChange={() => {
                      updatedPreferences.dataSets[0].screening.selected.HCProviderApi = 'MongoDB';
                      handleBlur(false);
                    }}
                    onBlur={() => handleBlur(false)}
                  />
                </td>
                <td className='pl-14'>
                  <input
                    type="radio"
                    name="hc-provider-api-type"
                    value="HL7FHIR"
                    checked={updatedPreferences.dataSets[0].screening.selected.HCProviderApi === 'HL7FHIR'}
                    onChange={() => {
                      updatedPreferences.dataSets[0].screening.selected.HCProviderApi = 'HL7FHIR';
                      handleBlur(false);
                    }}
                    onBlur={() => handleBlur(false)}
                  />
                </td>
              </tr>

              <tr>
                <td className='pl-4'>National Healthcare Agency</td>
                <td className='pl-14'>
                  <input
                    type="radio"
                    name="agency-api-type"
                    value="MongoDB"
                    checked={updatedPreferences.dataSets[0].screening.selected.HCAgencyApi === 'MongoDB'}
                    onChange={() => {
                      updatedPreferences.dataSets[0].screening.selected.HCAgencyApi = 'MongoDB';
                      handleBlur(false);
                    }}
                    onBlur={() => handleBlur(false)}
                  />
                </td>
                <td className='pl-14'>
                  <input
                    type="radio"
                    name="agency-api-type"
                    value="HL7FHIR"
                    checked={updatedPreferences.dataSets[0].screening.selected.HCAgencyApi === 'HL7FHIR'}
                    onChange={() => {
                      updatedPreferences.dataSets[0].screening.selected.HCAgencyApi = 'HL7FHIR';
                      handleBlur(false);
                    }}
                    onBlur={() => handleBlur(false)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="section">
          <h2 className="left-align">Clusters</h2>
          <p>Take control of your cluster deployment by choosing between the different cloud providers.</p>
          <table className="cluster-table">
            <thead>
              <tr>
                <th></th>
                <th>
                  <img src={MongoDBEALogo} alt="MongoDB Enterprise Logo" width="120" />
                </th>
                <th>
                  <img src={AWSLogo} alt="AWS Logo" width="50" className='ml-9' />
                </th>
                <th>
                  <img src={AzureLogo} alt="Azure Logo" width="100" className='ml-2'/>
                </th>
                <th>
                  <img src={GCPLogo} alt="GCP Logo" width="100"  className='ml-2'/>
                </th>
              </tr>
            </thead>
            <tbody className="px-5 py-5 bg-white rounded-lg border-b border-gray-200 bg-white shadow-md text-base">
              <tr >
                <td className='pl-4'>Healthcare Provider</td>
                <td className='pl-14'>
                  <input
                    type="radio"
                    name="hc-provider-cluster"
                    value="Inhouse"
                    checked={updatedPreferences.dataSets[0].screening.selected.HCProviderCluster === 'Inhouse'}
                    onChange={() => { updatedPreferences.dataSets[0].screening.selected.HCProviderCluster = 'Inhouse'; handleBlur(false); }}
                    onBlur={() => handleBlur(false)}
                    disabled={true}
                  />
                </td>
                <td className='pl-14'>
                  <input
                    type="radio"
                    name="hc-provider-cluster"
                    value="AWS"
                    checked={updatedPreferences.dataSets[0].screening.selected.HCProviderCluster === 'AWS'}
                    onChange={() => { updatedPreferences.dataSets[0].screening.selected.HCProviderCluster = 'AWS'; handleBlur(false); }}
                    onBlur={() => handleBlur(false)}
                  />
                </td>
                <td className='pl-14'>
                  <input
                    type="radio"
                    name="hc-provider-cluster"
                    value="Azure"
                    checked={updatedPreferences.dataSets[0].screening.selected.HCProviderCluster === 'Azure'}
                    onChange={() => { updatedPreferences.dataSets[0].screening.selected.HCProviderCluster = 'Azure'; handleBlur(false); }}
                    onBlur={() => handleBlur(false)}
                  />
                </td>
                <td className='pl-14'>
                  <input
                    type="radio"
                    name="hc-provider-cluster"
                    value="GCP"
                    checked={updatedPreferences.dataSets[0].screening.selected.HCProviderCluster === 'GCP'}
                    onChange={() => { updatedPreferences.dataSets[0].screening.selected.HCProviderCluster = 'GCP'; handleBlur(false); }}
                    onBlur={() => handleBlur(false)}
                  />
                </td>
              </tr>
              <tr>
                <td className='pl-4'>National Healthcare Agency</td>
                <td className='pl-14'>
                  <input
                    type="radio"
                    name="hc-national-agency-cluster"
                    value="Inhouse"
                    checked={updatedPreferences.dataSets[0].screening.selected.HCAgencyCluster === 'Inhouse'}
                    onChange={() => { updatedPreferences.dataSets[0].screening.selected.HCAgencyCluster = 'Inhouse'; handleBlur(false); }}
                    onBlur={() => handleBlur(false)}
                    disabled={true}
                  />
                </td>
                <td className='pl-14'>
                  <input
                    type="radio"
                    name="hc-national-agency-cluster"
                    value="AWS"
                    checked={updatedPreferences.dataSets[0].screening.selected.HCAgencyCluster === 'AWS'}
                    onChange={() => { updatedPreferences.dataSets[0].screening.selected.HCAgencyCluster = 'AWS'; handleBlur(false); }}
                    onBlur={() => handleBlur(false)}
                  />
                </td>
                <td className='pl-14'>
                  <input
                    type="radio"
                    name="hc-national-agency-cluster"
                    value="Azure"
                    checked={updatedPreferences.dataSets[0].screening.selected.HCAgencyCluster === 'Azure'}
                    onChange={() => { updatedPreferences.dataSets[0].screening.selected.HCAgencyCluster = 'Azure'; handleBlur(false); }}
                    onBlur={() => handleBlur(false)}
                  />
                </td>
                <td className='pl-14'>
                  <input
                    type="radio"
                    name="hc-national-agency-cluster"
                    value="GCP"
                    checked={updatedPreferences.dataSets[0].screening.selected.HCAgencyCluster === 'GCP'}
                    onChange={() => { updatedPreferences.dataSets[0].screening.selected.HCAgencyCluster = 'GCP'; handleBlur(false); }}
                    onBlur={() => handleBlur(false)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="section">
          <h2 className="left-align">Table loading</h2>
          <div className="table-loading-settings">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Load initial items</label>
            <input
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-40 p-2.5"
              type="text"
              value={updatedPreferences.screening.loadInitialAppointments}
              onChange={(event) => {
                setUpdatedPreferences((prevPreferences) => ({
                  ...prevPreferences,
                  screening: {
                    ...prevPreferences.screening,
                    loadInitialAppointments: event.target.value,
                  },
                }));
              }}
              onBlur={() => handleBlur(false)}
            />
            <label className="block my-2 text-sm font-medium text-gray-900 dark:text-white">Load more items</label>
            <input
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-40 p-2.5"
              type="text"
              value={updatedPreferences.screening.loadMoreAppointments}
              onChange={(event) => {
                setUpdatedPreferences((prevPreferences) => ({
                  ...prevPreferences,
                  screening: {
                    ...prevPreferences.screening,
                    loadMoreAppointments: event.target.value,
                  },
                }));
              }}
              onBlur={() => handleBlur(false)}
            />
          </div>
        </div>
        <div className="section">
          <h2 className="left-align">Atlas Search</h2>
          <div className="atlas-search">
            <textarea
              value={updatedPreferences.screening.atlasSearchStage}
              onChange={(event) => {
                setUpdatedPreferences((prevPreferences) => ({
                  ...prevPreferences,
                  screening: {
                    ...prevPreferences.screening,
                    atlasSearchStage: event.target.value,
                  },
                }));
              }}
              onBlur={() => handleBlur(false)}
              rows={1}
              className="one-line-textarea bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block p-2.5"
              style={{ width: '800px' }}
            />
          </div>
        </div>
        <div className="section">
          <h2 className="left-align">Dicom Image processor EndPoint</h2>
          <div className="dicom-image-endpoint">
            <textarea
              value={updatedPreferences.screening.DicomImageEndPoint}
              onChange={(event) => {
                setUpdatedPreferences((prevPreferences) => ({
                  ...prevPreferences,
                  screening: {
                    ...prevPreferences.screening,
                    DicomImageEndPoint: event.target.value,
                  },
                }));
              }}
              onBlur={() => handleBlur(false)}
              rows={1}
              className="one-line-textarea bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block p-2.5"
              style={{ width: '800px' }}
            />
          </div>
        </div>
        <div className="section">
        <h2 className="left-align">Atlas Vector Search</h2>
        <div className="atlas-vector-search">
        <div className="field mr-20">
            <label className="block my-2 text-sm font-medium text-gray-900 dark:text-white">Model</label>
            <textarea
              value={updatedPreferences.advancedSearch.LLMModel}
              onChange={(event) => {
                setUpdatedPreferences((prevPreferences) => ({
                  ...prevPreferences,
                  advancedSearch: {
                    ...prevPreferences.advancedSearch,
                    LLMModel: event.target.value,
                  },
                }));
              }}
              onBlur={() => handleBlur(false)}
              rows={1}
              className="one-line-textarea bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block p-2.5"
              style={{ width: '900px' }}
            />
          </div>
          <div className="field mr-20">
            <label className="block my-2 text-sm font-medium text-gray-900 dark:text-white">LLM-API-EndPoint</label>
            <textarea
              value={updatedPreferences.advancedSearch.LLMAPI}
              onChange={(event) => {
                setUpdatedPreferences((prevPreferences) => ({
                  ...prevPreferences,
                  advancedSearch: {
                    ...prevPreferences.advancedSearch,
                    LLMAPI: event.target.value,
                  },
                }));
              }}
              onBlur={() => handleBlur(false)}
              rows={1}
              className="one-line-textarea bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block p-2.5"
              style={{ width: '900px' }}
            />
          </div>
          <div className="field mr-20">
            <label className="block my-2 text-sm font-medium text-gray-900 dark:text-white">Vector Search</label>
            <textarea
              value={updatedPreferences.advancedSearch.vectorSearchStage}
              onChange={(event) => {
                setUpdatedPreferences((prevPreferences) => ({
                  ...prevPreferences,
                  advancedSearch: {
                    ...prevPreferences.advancedSearch,
                    vectorSearchStage: event.target.value,
                  },
                }));
              }}
              onBlur={() => handleBlur(false)}
              rows={1}
              className="one-line-textarea bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block p-2.5"
              style={{ width: '800px' }}
            />
          </div>
        </div>
      </div>
        <div className="subsection mt-10">
          <h2>Clinical notes</h2>
          <p>Customize the prompts for Chat GPT to generate accurate clinical notes.</p>
          {updatedPreferences.screening.generateTextService.prompts.map((prompt, promptIndex) => {
            if (prompt.type !== 'clinicalNotes') return null;
            return (
              <div key={promptIndex} className="prompt">
                {prompt.messages.map((message, messageIndex) => (
                  <div key={messageIndex} className="prompt-message">
                    <select
                      className='mr-4 text-black w-45 bg-gray-50 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center '
                      value={message.role}
                      onChange={(event) => handlePromptChange(promptIndex, messageIndex, 'role', event.target.value)}
                    >
                      <option value="system">System</option>
                      <option value="user">User</option>
                      <option value="assistant">Assistant</option>
                    </select>
                    <textarea
                      value={message.content}
                      onChange={(event) => handlePromptChange(promptIndex, messageIndex, 'content', event.target.value)}
                      onBlur={() => handleBlur(false)}
                      rows={2}
                      className="multi-line-textarea mt-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block p-2.5"
                      style={{ width: '900px' }}
                    />
                    <button onClick={() => handleRemovePrompt(promptIndex, messageIndex)} className="text-red-500 underline mb-4" >Remove</button>
                  </div>
                ))}
                {prompt.messages.length < 4 && (
                  <button onClick={() => handleAddPrompt(promptIndex)} className="restore-defaults-button px-4 py-1 border border-black text-gray-400 rounded-full hover:text-white hover:bg-gray-400 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2"
                  >Add Message</button>
                )}
              </div>
            );
          })}
        </div>
        <div className="subsection mt-10">
          <h2>Conclusions</h2>
          <p>Customize the prompts for Chat GPT to generate accurate conclusions.</p>
          {updatedPreferences.screening.generateTextService.prompts.map((prompt, promptIndex) => {
            if (prompt.type !== 'connclusions') return null;
            return (
              <div key={promptIndex} className="prompt">
                {prompt.messages.map((message, messageIndex) => (
                  <div key={messageIndex} className="prompt-message">
                    <select
                      value={message.role}
                      onChange={(event) => handlePromptChange(promptIndex, messageIndex, 'role', event.target.value)}
                    >
                      <option value="system">system</option>
                      <option value="user">user</option>
                      <option value="assistant">assistant</option>
                    </select>
                    <textarea
                      value={message.content}
                      onChange={(event) => handlePromptChange(promptIndex, messageIndex, 'content', event.target.value)}
                      onBlur={() => handleBlur(false)}
                      rows={2}
                      className="multi-line-textarea mt-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block p-2.5"
                      style={{ width: '900px' }}
                    />
                    <button onClick={() => handleRemovePrompt(promptIndex, messageIndex)} className="text-red-500 underline mb-4">Remove</button>
                  </div>
                ))}
                {prompt.messages.length < 4 && (
                  <button onClick={() => handleAddPrompt(promptIndex)} className="restore-defaults-button px-4 py-1 border border-black text-gray-400 rounded-full hover:text-white hover:bg-gray-400 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2"
                  >Add Message</button>
                )}
              </div>
            );
          })}
        </div>
        <div className="subsection">
          <h3>SNOMED</h3>
          {updatedPreferences.screening.generateTextService.prompts.map((prompt, promptIndex) => {
            if (prompt.type !== 'SNOMED') return null;
            return (
              <div key={promptIndex} className="prompt">
                {prompt.messages.map((message, messageIndex) => (
                  <div key={messageIndex} className="prompt-message">
                    <select
                      className='mr-4 text-black w-45 bg-gray-50 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center '
                      value={message.role}
                      onChange={(event) => handlePromptChange(promptIndex, messageIndex, 'role', event.target.value)}
                    >
                      <option value="system">System</option>
                      <option value="user">User</option>
                      <option value="assistant">Assistant</option>
                    </select>
                    <textarea
                      value={message.content}
                      onChange={(event) => handlePromptChange(promptIndex, messageIndex, 'content', event.target.value)}
                      onBlur={() => handleBlur(false)}
                      rows={2}
                      className="multi-line-textarea mt-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block p-2.5"
                      style={{ width: '900px' }}
                    />
                    <button onClick={() => handleRemovePrompt(promptIndex, messageIndex)} className="text-red-500 underline mb-4">Remove</button>
                  </div>
                ))}
                {prompt.messages.length < 4 && (
                  <button onClick={() => handleAddPrompt(promptIndex)} className="restore-defaults-button px-4 py-1 border border-black text-gray-400 rounded-full hover:text-white hover:bg-gray-400 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2"
                  >Add Message</button>
                )}
              </div>
            );
          })}
        </div>
      </div>

      <hr className='ml-7 mr-20' />
      <div className="section">
        <h2 className="left-align">Settings</h2>

        <div className="section ml-0">
          <p className="left-align block my-2 text-sm font-medium text-gray-900 dark:text-white">Language</p>
          <select
            className='mr-4 text-black w-45 bg-gray-50 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center '
            value={updatedPreferences.locale.lng}
            onChange={(event) => { updatedPreferences.locale.lng = event.target.value; }}
            onBlur={() => handleBlur(true)}
          >
            <option value="en">English</option>
            <option value="es">Spanish</option>
          </select>
        </div>
        <div className="section ml-0">
          <p className="left-align block my-2 text-sm font-medium text-gray-900 dark:text-white">Time Zone</p>
          <select
            className='mr-4 text-black w-30 bg-gray-50 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center '
            value={updatedPreferences.TimeZone}
            onChange={(event) => {
              setUpdatedPreferences((prevPreferences) => ({
                ...prevPreferences,
                TimeZone: event.target.value,
              }));
            }}
            onBlur={() => handleBlur(false)}
          >
            {timezones.map((timezone) => (
              <option key={timezone.value} value={timezone.value}>
                {timezone.text}
              </option>
            ))}
          </select>
        </div>
      </div>
      </React.Fragment>
    );
  };
  
  export default Preferences;
