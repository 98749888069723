import React from 'react';
import { useState } from 'react';
import logo from './img/MongoDB_SlateBlue.png';
import InspectConsoleNew from '../InspectConsole/InspectConsoleNew';
import console from './img/console.png';
import { logActivity } from '../../services/LoggerService';


function Footer() {
    const [consoleVisible, setConsoleVisible] = useState(false);

    const toggleConsole = () => {
        setConsoleVisible(!consoleVisible);
      };
      
    const closeConsole = () => {
        setConsoleVisible(false);
    };

    return (
        <footer className="bg-gray-50 text-black py-3 fixed bottom-0 left-0 w-full h-12 z-50">
            <div className="container mx-auto flex items-center justify-between">
                <div className="text-sm">

                    <button 
                     onClick={toggleConsole}
                     className="flex items-center justify-between px-2 rounded-xl bg-white shadow-md text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">

                        <div className="flex items-center">
                            <img
                               className="h-4 w-4 rounded-full"
                               src={console}
                               alt=""
                            />
                            <div className="text-left ml-1">
                                <p className='mb-0 text-gray-500 '>Open Console</p>
                            </div>
                        </div>
                    </button>

                </div>
                <div className="flex items-center">
                    <img src={logo} alt="Logo" className="w-24 h-6 mr-2" />
                </div>
            </div>

       {consoleVisible && (
        <InspectConsoleNew onClose={closeConsole} activityLog={logActivity.getActivityLog()} />
      )}
        </footer>
    );
}

export default Footer;
