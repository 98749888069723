import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';

export default function OverallStatus() {

    return (

        <Container className="items-center">

            <div className="py-4 flex flex-wrap justify-center items-center space-y-4">

                <div className="rounded-xl shadow-md space-y-2 ratio ratio-4x3">
                    <iframe src="https://charts.mongodb.com/charts-jeffn-zsdtj/embed/charts?id=64affec5-f6b1-4e72-85c4-cb44515f697e&maxDataAge=3600&theme=light&autoRefresh=true" title="Total" allowfullscreen className="w-80 h-50"></iframe>
                </div>

                <div className="ml-4 mt-0 rounded-xl shadow-md space-y-2 ratio ratio-4x3">
                    <iframe src="https://charts.mongodb.com/charts-jeffn-zsdtj/embed/charts?id=64affb14-0ea1-40e5-87af-01f693096afe&maxDataAge=3600&theme=light&autoRefresh=true" title="Clinics" allowfullscreen className="w-80 h-50"></iframe>
                </div>

                <div className="ml-4 mt-0 rounded-xl shadow-md space-y-2 ratio ratio-4x3">
                    <iframe src="https://charts.mongodb.com/charts-jeffn-zsdtj/embed/charts?id=64af9c1c-6745-486f-8a83-428e46321df9&maxDataAge=3600&theme=light&autoRefresh=true" title="Total appts." allowfullscreen className="w-80 h-50"></iframe>
                </div>

                <div className="rounded-xl shadow-md space-y-2 ratio ratio-4x3">
                    <iframe src="https://charts.mongodb.com/charts-jeffn-zsdtj/embed/charts?id=648b1891-1d45-457f-8cb6-0b9c62f15277&maxDataAge=3600&theme=light&autoRefresh=true" title="Status" allowfullscreen className="w-80 h-80"></iframe>
                </div>


                <div className="ml-4 rounded-xl shadow-md space-y-2 ratio ratio-4x3">
                    <iframe src="https://charts.mongodb.com/charts-jeffn-zsdtj/embed/charts?id=64af9d39-0ea1-4beb-8dcf-01f693d63919&maxDataAge=3600&theme=light&autoRefresh=true" title="Appts. per Clinic" allowfullscreen className="w-80 h-80"></iframe>
                </div>


                <div className="ml-4 rounded-xl shadow-md space-y-2">
                    <iframe src="https://charts.mongodb.com/charts-jeffn-zsdtj/embed/charts?id=63ffa2d9-0582-44ec-82c7-a539d45c4cd4&maxDataAge=3600&theme=light&autoRefresh=true" title="Observations per cat" allowfullscreen className="w-80 h-80"></iframe>
                </div>

            </div>


        </Container>

    )

}