import React, { useState, useContext } from 'react';
import ImageContainer from '../../components/ImageContainer/ImageContainer'
import './ReportCard.css';
import { AppContext } from '../../context/AppContext';
import patientPhoto from './img/patient.png';



const ReportCard = ({ patientName, patientID, appointmentID, score, conclusions, clinicalNotes, series, date, displayExpandedRow, patientLastName }) => {
  const formattedDate = date.toISOString().substring(0, 10);
  const patientAge = parseInt(series[0].imageMetadata.PatientAge, 10);
  const { translations } = useContext(AppContext);
  const formattedScore = score !== null ? score.toFixed(6) : '';  

  return (
    <div className="report mt-10 rounded-xl shadow-md" style={{ maxHeight: '400px', overflowY: 'auto' }}>
      <div className="report-header">
        <div className="avatar">
          <img alt="patient" src={patientPhoto} />
        </div>
        <div className="patient-info">
          <div className='font-medium'>{patientName} {patientLastName}</div>
          <div className="age text-xs">{patientAge}{translations.report.yearsOld} years old</div>
          <div className="clinic text-sm">{translations.report.center} {series[0]?.imageMetadata.InstitutionName}</div>
        </div>
        <div className="tab-container">
          <div className="tab">Report Date: {formattedDate}</div>
        </div>
      </div>
      <div className="report-content">
        <div className="top-containers">
          <div className="clinical-notes-container">
            <div className="container-title">{translations.report.clinicalNotes}</div>
            <p>{clinicalNotes}</p>
          </div>
          <div className="conclusions-container">
            <div className="container-title">{translations.report.conclusions}</div>
            <p>{conclusions}</p>
          </div>
        </div>
        <div className="images-container">
          <div className="container-title">{translations.report.imageStudy}</div>
          <div className="imagePlaceholders">
            <ImageContainer laterality='L' viewPosition='CC' imageDetails={series[0]?.imageMetadata} />
            <ImageContainer laterality='L' viewPosition='MLO' imageDetails={series[1]?.imageMetadata} />
            <ImageContainer laterality='R' viewPosition='CC' imageDetails={series[2]?.imageMetadata} />
            <ImageContainer laterality='R' viewPosition='MLO' imageDetails={series[3]?.imageMetadata} />
          </div>
        </div>
      </div>
      {score !== null && (
  <div className="search-score">{translations.report.searchScore} {formattedScore}</div>
)}

    </div>
  );
};


export default ReportCard

